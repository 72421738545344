import { SUPPORTS_DASH, SUPPORTS_HLS } from "./videoUtils";

import React, {
	useRef,
	useState,
	useEffect,
	forwardRef,
	useContext,
} from "react";

const ReactPlayer = require("react-player/file");

const WrapperPropsContext = React.createContext();

const VideoPlayer = forwardRef(function (
	{
		url,
		hlsUrl,
		dashUrl,
		fileUrl,
		className,
		progressInterval,
		playsInline,
		autoPlay,
		preload,
		onPlay,
		onPause,
		onProgress,
		onLoadedMetadata,
		...props
	},
	ref
) {
	const [playing, setPlaying] = useState(autoPlay);
	const playerRef = useRef();

	if (typeof url === "string") {
		url = { file: url };
	}

	useEffect(() => {
		if (ref) {
			ref.current = {
				paused: playerRef?.current?.getInternalPlayer()?.paused,
				duration: playerRef?.current?.getInternalPlayer()?.duration,
				play: () => {
					setPlaying(true);
					return playerRef?.current?.getInternalPlayer()?.play();
				},
				pause: () => {
					setPlaying(false);
					return playerRef?.current?.getInternalPlayer()?.pause();
				},
				seekTo: (time) => {
					const player = playerRef?.current?.getInternalPlayer();
					if (player) {
						player.currentTime = time;
					}
					// TODO: the following doesn't work. figure out why
					// playerRef?.current?.seekTo(time, "seconds");
				},
			};
		}
	}, [ref]);

	function progressHandler(progress) {
		if (ref && ref.current) {
			ref.current.currentTime = progress.playedSeconds;
		}
		onProgress && onProgress(progress);
	}

	function metadataLoadedHandler(evt) {
		if (ref && ref.current) {
			ref.current.duration =
				playerRef?.current?.getInternalPlayer()?.duration;
		}
		onLoadedMetadata && onLoadedMetadata(evt);
	}

	function playHandler(evt) {
		if (ref && ref.current) {
			ref.current.paused = false;
		}
		onPlay && onPlay(evt);
	}

	function pauseHandler(evt) {
		if (ref && ref.current) {
			ref.current.paused = true;
		}
		onPause && onPause(evt);
	}

	return (
		<WrapperPropsContext.Provider value={{ className }}>
			<ReactPlayer
				ref={playerRef}
				url={selectVideoUrl({
					hlsUrl: url?.hls || hlsUrl,
					dashUrl: url?.dash || dashUrl,
					fileUrl: url?.file || fileUrl,
				})}
				wrapper={Wrapper}
				playing={playing}
				config={{
					file: {
						hlsVersion: "1.0.10",
						dashVersion: "4.0.0-npm",
						attributes: {
							autoPlay,
							preload,
							disableRemotePlayback: true,
							disablePictureInPicture: true,
						},
					},
				}}
				playsinline={playsInline}
				progressInterval={progressInterval}
				onPlay={playHandler}
				onPause={pauseHandler}
				onProgress={progressHandler}
				onLoadedMetadata={metadataLoadedHandler}
				{...props}
			/>
		</WrapperPropsContext.Provider>
	);
});

VideoPlayer.displayName = "VideoPlayer";

VideoPlayer.defaultProps = {
	autoPlay: false,
	playsInline: true,
	progressInterval: 500,
};

export default VideoPlayer;

const Wrapper = forwardRef(({ children, ...props }, ref) => {
	const wrapperProps = useContext(WrapperPropsContext) || {};

	return (
		<div ref={ref} {...wrapperProps} {...props}>
			{children}
		</div>
	);
});

function selectVideoUrl({ hlsUrl, dashUrl, fileUrl }) {
	if (hlsUrl && SUPPORTS_HLS) {
		return hlsUrl;
	}
	if (dashUrl && SUPPORTS_DASH) {
		return dashUrl;
	}
	return fileUrl;
}
