const unistrokes = [
	[
		[2, 0],
		[10, 0],
		[15, 0],
		[24, -1],
		[35, -3],
		[47, -5],
		[60, -9],
		[74, -12],
		[86, -15],
		[93, -15],
		[104, -18],
		[113, -21],
		[120, -23],
		[128, -25],
		[134, -27],
		[139, -30],
		[142, -31],
		[146, -32],
		[150, -34],
		[153, -35],
		[155, -36],
		[156, -37],
		[157, -38],
		[159, -38],
		[159, -39],
		[159, -39],
		[159, -39],
		[160, -39],
		[160, -40],
		[160, -40],
		[160, -40],
		[159, -40],
	],
	[
		[3, 0],
		[4, 0],
		[6, -1],
		[8, -2],
		[10, -2],
		[12, -3],
		[15, -5],
		[18, -6],
		[19, -6],
		[22, -7],
		[24, -9],
		[29, -12],
		[30, -13],
		[33, -16],
		[34, -17],
		[36, -20],
		[40, -24],
		[41, -27],
		[42, -29],
		[44, -33],
		[46, -36],
		[47, -42],
		[48, -47],
		[50, -53],
		[50, -60],
		[51, -67],
		[52, -73],
		[52, -80],
		[52, -87],
		[52, -93],
		[52, -101],
		[52, -108],
		[49, -115],
		[45, -123],
		[41, -131],
		[36, -138],
		[30, -145],
		[19, -157],
		[15, -160],
		[6, -167],
		[-2, -173],
		[-11, -178],
		[-20, -182],
		[-29, -185],
	],
	[
		[-11, 0],
		[-24, 0],
		[-45, 0],
		[-69, 0],
		[-127, 0],
		[-161, 0],
		[-192, 0],
		[-206, 0],
		[-232, 0],
		[-264, 2],
		[-281, 5],
		[-294, 10],
		[-305, 14],
		[-312, 20],
		[-316, 26],
		[-321, 36],
		[-321, 44],
		[-321, 53],
		[-316, 62],
		[-307, 71],
		[-294, 78],
		[-264, 88],
		[-237, 90],
		[-204, 90],
		[-168, 82],
		[-128, 67],
		[-88, 50],
		[-71, 42],
		[-37, 25],
		[-28, 18],
		[-20, 14],
		[-6, 6],
		[3, 0],
		[12, -5],
		[13, -6],
		[15, -6],
		[15, -5],
		[15, 9],
		[15, 30],
		[15, 61],
		[11, 97],
		[10, 115],
		[6, 150],
		[4, 182],
		[2, 191],
		[1, 199],
		[0, 214],
		[0, 224],
		[-1, 229],
		[-1, 233],
		[-1, 235],
		[-1, 236],
		[-1, 236],
		[0, 236],
		[1, 236],
		[2, 236],
		[3, 236],
		[3, 235],
		[3, 235],
		[4, 235],
		[4, 235],
		[4, 235],
		[4, 235],
		[4, 234],
		[4, 234],
	],
	[
		[0, -5],
		[1, -12],
		[4, -23],
		[8, -37],
		[19, -72],
		[27, -99],
		[36, -129],
		[39, -143],
		[54, -200],
		[56, -212],
		[61, -239],
		[62, -250],
		[63, -271],
		[64, -288],
		[64, -300],
		[64, -310],
		[62, -315],
		[59, -319],
		[55, -322],
		[48, -322],
		[40, -322],
		[32, -319],
		[20, -311],
		[8, -303],
		[-5, -293],
		[-11, -287],
		[-21, -277],
		[-30, -270],
		[-33, -267],
		[-38, -263],
		[-42, -260],
		[-44, -258],
		[-45, -257],
		[-46, -257],
		[-46, -256],
		[-46, -258],
		[-45, -261],
		[-44, -263],
		[-43, -265],
		[-41, -267],
		[-40, -268],
		[-40, -269],
		[-40, -270],
		[-39, -270],
		[-39, -270],
		[-39, -271],
		[-37, -272],
		[-32, -275],
		[-24, -280],
		[-7, -289],
		[11, -298],
		[37, -307],
		[69, -319],
		[109, -330],
		[152, -339],
		[196, -346],
		[237, -352],
		[254, -352],
		[266, -352],
		[291, -352],
		[297, -352],
		[310, -352],
		[317, -352],
		[322, -352],
		[324, -351],
		[326, -350],
		[326, -345],
		[321, -337],
		[309, -322],
		[292, -302],
		[269, -274],
		[213, -207],
		[180, -174],
		[147, -140],
		[132, -128],
		[106, -104],
		[96, -97],
		[77, -81],
		[61, -69],
		[57, -66],
		[50, -60],
		[44, -57],
		[41, -55],
		[39, -53],
		[39, -53],
		[38, -53],
		[38, -53],
		[40, -59],
		[42, -62],
		[50, -72],
		[54, -77],
		[60, -84],
		[68, -92],
		[82, -105],
		[93, -112],
		[104, -117],
		[116, -121],
		[129, -122],
		[135, -122],
		[146, -122],
		[163, -119],
		[173, -113],
		[183, -103],
		[193, -90],
		[202, -75],
		[221, -44],
		[234, -19],
		[241, -7],
		[252, 14],
		[256, 20],
		[267, 36],
		[276, 48],
		[283, 56],
		[290, 61],
		[295, 64],
		[301, 66],
		[308, 67],
		[314, 67],
	],
	[
		[0, -2],
		[0, -2],
		[0, -2],
		[0, -3],
		[-1, -4],
		[-2, -5],
		[-4, -8],
		[-6, -11],
		[-10, -16],
		[-13, -20],
		[-18, -25],
		[-23, -30],
		[-29, -36],
		[-41, -46],
		[-49, -53],
		[-58, -61],
		[-67, -70],
		[-76, -78],
		[-80, -83],
		[-89, -91],
		[-97, -99],
		[-103, -105],
		[-109, -111],
		[-114, -116],
		[-119, -122],
		[-121, -124],
		[-127, -130],
		[-128, -132],
		[-130, -134],
		[-132, -137],
		[-134, -139],
		[-135, -141],
		[-136, -143],
		[-137, -145],
		[-137, -148],
		[-137, -150],
		[-137, -152],
		[-138, -154],
		[-138, -156],
		[-138, -158],
		[-138, -162],
		[-138, -164],
		[-136, -167],
		[-130, -174],
		[-124, -179],
		[-117, -184],
		[-111, -187],
		[-102, -192],
		[-93, -196],
		[-85, -198],
		[-77, -199],
		[-70, -200],
		[-68, -200],
		[-63, -200],
		[-59, -200],
		[-56, -200],
		[-54, -200],
		[-53, -200],
		[-52, -200],
		[-50, -200],
		[-49, -200],
		[-48, -200],
		[-47, -200],
		[-46, -200],
		[-45, -201],
		[-44, -201],
		[-43, -202],
		[-41, -204],
		[-40, -206],
		[-38, -207],
		[-37, -209],
		[-36, -210],
		[-35, -212],
		[-32, -216],
		[-30, -219],
		[-28, -222],
		[-24, -227],
		[-17, -236],
		[-13, -240],
		[-8, -246],
		[-2, -253],
		[1, -255],
		[4, -259],
		[8, -264],
		[11, -267],
		[14, -269],
		[16, -272],
		[17, -273],
		[19, -275],
		[20, -276],
		[21, -277],
		[22, -278],
		[24, -278],
		[24, -279],
		[24, -279],
		[25, -279],
		[25, -279],
		[26, -279],
		[26, -279],
		[27, -279],
		[27, -279],
		[28, -279],
	],
];
export default unistrokes;
