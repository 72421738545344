const unistrokes = [
	[
		[4, -13],
		[8, -19],
		[13, -26],
		[18, -36],
		[28, -55],
		[31, -60],
		[37, -69],
		[41, -75],
		[42, -77],
		[50, -87],
		[50, -88],
		[52, -90],
		[56, -93],
		[59, -95],
		[60, -96],
		[61, -96],
		[62, -96],
		[63, -96],
		[63, -96],
		[63, -96],
		[64, -96],
		[64, -96],
		[65, -94],
		[66, -92],
		[68, -87],
		[71, -81],
		[75, -71],
		[80, -59],
		[87, -45],
		[95, -31],
		[99, -24],
		[104, -15],
		[112, -5],
		[117, 2],
		[119, 3],
		[123, 7],
		[126, 10],
		[129, 12],
		[134, 14],
		[135, 14],
		[137, 14],
		[140, 14],
		[143, 14],
		[145, 12],
		[148, 10],
		[152, 6],
		[159, -1],
		[164, -7],
		[180, -27],
		[188, -37],
		[207, -61],
		[220, -75],
		[225, -80],
		[236, -90],
		[245, -99],
		[254, -107],
		[261, -113],
		[266, -117],
		[268, -119],
		[272, -122],
	],
	[
		[4, -7],
		[10, -12],
		[24, -29],
		[34, -41],
		[43, -55],
		[56, -69],
		[66, -82],
		[79, -99],
		[89, -111],
		[93, -116],
		[99, -124],
		[101, -126],
		[109, -133],
		[112, -136],
		[113, -136],
		[114, -136],
		[114, -136],
		[115, -136],
		[115, -136],
		[115, -136],
		[115, -136],
		[117, -134],
		[119, -130],
		[123, -125],
		[129, -119],
		[135, -111],
		[153, -87],
		[164, -73],
		[175, -59],
		[179, -54],
		[188, -42],
		[196, -33],
		[201, -26],
		[204, -24],
		[208, -19],
		[212, -16],
		[214, -13],
		[216, -11],
		[217, -10],
		[218, -10],
		[220, -9],
		[220, -9],
		[221, -8],
		[222, -8],
		[223, -8],
		[224, -8],
		[226, -8],
		[228, -10],
		[230, -12],
		[234, -16],
		[238, -23],
		[244, -34],
		[250, -45],
		[259, -60],
		[269, -81],
		[289, -120],
		[294, -130],
		[319, -181],
		[328, -199],
		[342, -226],
		[352, -239],
	],
	[
		[0, -3],
		[0, -5],
		[0, -8],
		[0, -11],
		[1, -13],
		[3, -15],
		[5, -19],
		[9, -25],
		[14, -32],
		[18, -36],
		[21, -40],
		[26, -45],
		[28, -47],
		[30, -48],
		[31, -49],
		[33, -50],
		[34, -50],
		[34, -50],
		[34, -50],
		[34, -50],
		[35, -50],
		[35, -50],
		[37, -45],
		[42, -36],
		[45, -30],
		[53, -18],
		[63, -2],
		[72, 10],
		[77, 17],
		[80, 21],
		[82, 23],
		[84, 25],
		[87, 29],
		[88, 30],
		[89, 31],
		[90, 31],
		[91, 32],
		[92, 32],
		[93, 33],
		[95, 33],
		[97, 33],
		[98, 33],
		[101, 31],
		[103, 29],
		[108, 25],
		[116, 17],
		[122, 10],
		[130, 2],
		[133, -1],
		[147, -16],
		[155, -24],
		[160, -31],
		[165, -36],
		[172, -44],
		[175, -47],
		[178, -49],
		[180, -51],
	],
	[
		[2, -4],
		[5, -8],
		[10, -16],
		[15, -23],
		[23, -33],
		[30, -43],
		[36, -51],
		[39, -54],
		[45, -63],
		[48, -68],
		[53, -73],
		[57, -78],
		[60, -83],
		[63, -86],
		[63, -87],
		[66, -89],
		[67, -91],
		[68, -92],
		[69, -93],
		[69, -93],
		[70, -93],
		[70, -93],
		[70, -93],
		[70, -93],
		[71, -91],
		[71, -91],
		[75, -85],
		[76, -82],
		[81, -74],
		[85, -69],
		[88, -64],
		[91, -59],
		[95, -54],
		[98, -48],
		[102, -42],
		[106, -36],
		[109, -30],
		[110, -28],
		[112, -24],
		[114, -21],
		[115, -19],
		[116, -17],
		[117, -17],
		[117, -16],
		[118, -16],
		[118, -16],
		[119, -16],
		[120, -16],
		[125, -19],
		[131, -25],
		[138, -30],
		[147, -37],
		[157, -46],
		[169, -56],
		[180, -66],
		[192, -79],
		[203, -91],
		[209, -97],
		[218, -108],
		[228, -118],
		[235, -127],
		[238, -129],
		[242, -134],
		[245, -138],
		[248, -140],
		[250, -141],
	],
	[
		[0, -4],
		[1, -6],
		[5, -13],
		[7, -18],
		[10, -22],
		[11, -27],
		[14, -31],
		[16, -35],
		[19, -38],
		[20, -40],
		[26, -47],
		[28, -50],
		[30, -52],
		[32, -54],
		[33, -55],
		[35, -57],
		[36, -59],
		[38, -60],
		[38, -61],
		[39, -61],
		[39, -61],
		[40, -61],
		[40, -61],
		[41, -61],
		[41, -61],
		[41, -61],
		[43, -58],
		[45, -55],
		[47, -52],
		[50, -47],
		[54, -42],
		[58, -34],
		[63, -26],
		[68, -17],
		[73, -8],
		[78, 1],
		[82, 7],
		[87, 14],
		[91, 19],
		[93, 21],
		[96, 25],
		[100, 28],
		[102, 31],
		[104, 32],
		[105, 33],
		[107, 34],
		[108, 35],
		[108, 35],
		[109, 35],
		[109, 35],
		[110, 35],
		[111, 34],
		[113, 32],
		[115, 29],
		[118, 26],
		[121, 21],
		[125, 15],
		[134, 2],
		[140, -6],
		[144, -12],
		[155, -26],
		[159, -33],
		[164, -40],
		[168, -47],
		[169, -49],
		[172, -54],
		[174, -57],
		[176, -60],
		[178, -61],
		[178, -62],
		[179, -63],
		[179, -63],
		[179, -63],
		[179, -63],
	],
	[
		[0, -2],
		[0, -4],
		[2, -6],
		[3, -9],
		[5, -12],
		[9, -16],
		[14, -24],
		[19, -31],
		[22, -37],
		[26, -41],
		[30, -46],
		[33, -50],
		[37, -54],
		[37, -55],
		[40, -56],
		[41, -58],
		[43, -59],
		[45, -61],
		[46, -62],
		[47, -62],
		[47, -62],
		[48, -63],
		[48, -63],
		[49, -63],
		[49, -63],
		[49, -63],
		[49, -63],
		[50, -63],
		[50, -61],
		[51, -59],
		[53, -56],
		[58, -48],
		[62, -41],
		[67, -32],
		[69, -27],
		[74, -20],
		[83, -5],
		[85, -3],
		[88, 2],
		[91, 5],
		[93, 8],
		[94, 10],
		[96, 11],
		[96, 11],
		[97, 12],
		[97, 12],
		[97, 12],
		[97, 12],
		[98, 12],
		[98, 12],
		[98, 12],
		[98, 12],
		[99, 12],
		[101, 11],
		[103, 8],
		[106, 5],
		[109, 1],
		[116, -8],
		[122, -16],
		[126, -21],
		[132, -29],
		[139, -38],
		[145, -46],
		[150, -53],
		[152, -56],
		[156, -61],
		[159, -65],
		[161, -69],
		[163, -71],
		[165, -74],
		[167, -75],
		[168, -77],
		[169, -78],
		[169, -78],
		[169, -78],
		[169, -78],
	],
];
export default unistrokes;
