import sign1 from "./sign1";
import sign2 from "./sign2";
import sign3 from "./sign3";
import sign4 from "./sign4";
import sign5 from "./sign5";
import triangle from "./triangle";
import circle from "./circle";
import random from "./random";

const GESTURES = [
	{
		name: "sign1",
		unistrokes: sign1,
	},
	{
		name: "sign2",
		unistrokes: sign2,
	},
	{
		name: "sign3",
		unistrokes: sign3,
	},
	{
		name: "sign4",
		unistrokes: sign4,
	},
	{
		name: "sign5",
		unistrokes: sign5,
	},
	{
		name: "triangle",
		unistrokes: triangle,
	},
	{
		name: "circle",
		unistrokes: circle,
	},
	{
		name: "random",
		unistrokes: random,
	},
];

export default GESTURES;
