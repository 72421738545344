const unistrokes = [
	[
		[-3, 0],
		[-4, 0],
		[-6, 0],
		[-8, 0],
		[-9, 0],
		[-11, 0],
		[-13, 0],
		[-14, 0],
		[-17, 0],
		[-18, 0],
		[-20, 0],
		[-22, 0],
		[-24, 0],
		[-27, 0],
		[-29, 0],
		[-33, 0],
		[-35, 0],
		[-37, 0],
		[-40, 0],
		[-43, 0],
		[-45, 0],
		[-48, 0],
		[-51, 0],
		[-53, 0],
		[-55, 0],
		[-57, 0],
		[-59, 0],
		[-61, 0],
		[-63, 0],
		[-65, 0],
		[-67, 0],
		[-68, 1],
		[-70, 1],
		[-71, 2],
		[-73, 3],
		[-75, 5],
		[-76, 5],
		[-77, 6],
		[-78, 7],
		[-78, 8],
		[-79, 9],
		[-80, 10],
		[-81, 12],
		[-81, 13],
		[-81, 15],
		[-81, 16],
		[-81, 17],
		[-81, 18],
		[-81, 19],
		[-81, 20],
		[-81, 22],
		[-81, 23],
		[-81, 24],
		[-81, 25],
		[-81, 26],
		[-81, 27],
		[-81, 28],
		[-80, 29],
		[-79, 30],
		[-78, 31],
		[-77, 32],
		[-76, 32],
		[-75, 33],
		[-74, 34],
		[-72, 35],
		[-71, 36],
		[-70, 36],
		[-68, 37],
		[-67, 38],
		[-64, 39],
		[-63, 39],
		[-61, 40],
		[-59, 40],
		[-57, 41],
		[-56, 42],
		[-53, 42],
		[-51, 42],
		[-50, 43],
		[-48, 43],
		[-46, 43],
		[-44, 43],
		[-43, 43],
		[-40, 43],
		[-38, 43],
		[-37, 43],
		[-35, 43],
		[-33, 43],
		[-31, 43],
		[-29, 43],
		[-26, 43],
		[-24, 43],
		[-22, 43],
		[-18, 42],
		[-17, 42],
		[-12, 41],
		[-9, 40],
		[-7, 40],
		[-2, 39],
		[1, 38],
		[4, 38],
		[8, 37],
		[12, 36],
		[16, 36],
		[19, 35],
		[23, 35],
		[27, 34],
		[30, 34],
		[35, 33],
		[38, 33],
		[43, 32],
		[47, 32],
		[51, 32],
		[56, 32],
		[58, 32],
		[64, 32],
		[66, 32],
		[73, 32],
		[75, 32],
		[78, 32],
		[82, 32],
		[84, 32],
		[87, 33],
		[90, 33],
		[94, 35],
		[95, 36],
		[99, 38],
		[100, 39],
		[102, 40],
		[105, 42],
		[106, 43],
		[108, 45],
		[109, 47],
		[111, 48],
		[112, 50],
		[113, 52],
		[114, 54],
		[115, 56],
		[116, 60],
		[117, 61],
		[118, 64],
		[118, 66],
		[119, 69],
		[119, 71],
		[120, 73],
		[120, 75],
		[120, 77],
		[120, 79],
		[120, 80],
		[120, 82],
		[120, 84],
		[120, 86],
		[120, 87],
		[119, 89],
		[118, 90],
		[117, 92],
		[116, 94],
		[115, 95],
		[114, 96],
		[112, 98],
		[111, 99],
		[110, 100],
		[109, 101],
		[107, 102],
		[106, 103],
		[104, 104],
		[103, 105],
		[101, 106],
		[99, 107],
		[97, 107],
		[95, 108],
		[93, 109],
		[92, 109],
		[90, 109],
		[89, 110],
		[87, 110],
		[86, 111],
		[84, 111],
		[83, 111],
		[82, 111],
		[82, 111],
		[81, 111],
		[80, 111],
		[79, 111],
		[78, 111],
		[78, 111],
		[77, 111],
		[76, 111],
		[75, 110],
		[74, 110],
		[73, 109],
		[72, 108],
		[71, 107],
		[70, 106],
		[69, 106],
		[68, 105],
		[67, 104],
		[65, 103],
		[64, 102],
		[63, 101],
		[62, 100],
		[61, 98],
		[59, 97],
		[59, 96],
		[57, 94],
		[56, 93],
		[55, 92],
		[55, 91],
		[54, 90],
		[54, 90],
		[54, 89],
		[53, 89],
		[53, 89],
		[53, 89],
		[53, 89],
		[53, 88],
		[53, 88],
	],
	[
		[-2, 0],
		[-4, 0],
		[-6, 0],
		[-9, 0],
		[-12, 0],
		[-16, 0],
		[-19, 0],
		[-22, 0],
		[-25, 0],
		[-29, 0],
		[-32, 0],
		[-32, 0],
		[-35, 0],
		[-37, 0],
		[-42, 1],
		[-43, 2],
		[-45, 3],
		[-46, 4],
		[-48, 5],
		[-49, 6],
		[-50, 8],
		[-51, 9],
		[-53, 11],
		[-54, 12],
		[-54, 14],
		[-55, 16],
		[-55, 18],
		[-55, 20],
		[-55, 22],
		[-55, 25],
		[-55, 27],
		[-55, 30],
		[-55, 32],
		[-55, 35],
		[-53, 36],
		[-52, 39],
		[-51, 40],
		[-48, 43],
		[-45, 46],
		[-42, 47],
		[-38, 49],
		[-34, 51],
		[-30, 52],
		[-25, 53],
		[-20, 55],
		[-16, 56],
		[-13, 57],
		[-9, 57],
		[-5, 58],
		[-2, 58],
		[2, 58],
		[7, 58],
		[10, 58],
		[13, 58],
		[15, 57],
		[18, 56],
		[22, 54],
		[25, 52],
		[29, 50],
		[32, 49],
		[39, 44],
		[43, 42],
		[47, 39],
		[55, 33],
		[64, 27],
		[74, 21],
		[79, 18],
		[83, 16],
		[87, 13],
		[93, 11],
		[97, 9],
		[102, 8],
		[106, 6],
		[108, 6],
		[114, 5],
		[119, 4],
		[120, 4],
		[126, 3],
		[128, 3],
		[131, 3],
		[135, 3],
		[137, 3],
		[140, 3],
		[142, 5],
		[144, 6],
		[146, 7],
		[148, 8],
		[149, 10],
		[151, 12],
		[152, 14],
		[153, 16],
		[154, 17],
		[155, 19],
		[155, 21],
		[156, 23],
		[156, 25],
		[156, 27],
		[156, 29],
		[156, 31],
		[156, 33],
		[156, 35],
		[156, 36],
		[156, 38],
		[156, 39],
		[155, 41],
		[155, 42],
		[154, 43],
		[153, 45],
		[153, 46],
		[152, 48],
		[151, 49],
		[150, 49],
		[149, 51],
		[147, 52],
		[146, 53],
		[145, 54],
		[144, 55],
		[142, 56],
		[141, 57],
		[139, 58],
		[138, 58],
		[136, 59],
		[135, 59],
		[133, 60],
		[132, 60],
		[130, 61],
		[128, 61],
		[126, 61],
		[125, 61],
		[123, 61],
		[121, 61],
		[120, 61],
		[118, 61],
		[115, 61],
		[112, 61],
		[109, 60],
		[108, 59],
		[105, 58],
		[103, 57],
		[95, 54],
		[93, 53],
		[92, 53],
		[91, 53],
		[89, 52],
		[88, 52],
		[87, 52],
		[85, 52],
		[84, 52],
		[83, 52],
		[83, 52],
		[82, 51],
		[81, 51],
		[81, 51],
		[80, 51],
		[80, 51],
		[80, 51],
		[79, 51],
		[79, 51],
	],
	[
		[-2, 0],
		[-3, 0],
		[-6, 0],
		[-8, 0],
		[-10, 0],
		[-13, 0],
		[-17, 0],
		[-20, 0],
		[-21, 0],
		[-25, 0],
		[-26, 0],
		[-29, 0],
		[-32, 0],
		[-34, 0],
		[-35, 0],
		[-39, 1],
		[-41, 2],
		[-42, 2],
		[-44, 3],
		[-46, 4],
		[-47, 5],
		[-48, 6],
		[-50, 7],
		[-50, 8],
		[-52, 11],
		[-53, 13],
		[-53, 15],
		[-53, 16],
		[-53, 20],
		[-53, 23],
		[-53, 26],
		[-53, 29],
		[-53, 30],
		[-52, 33],
		[-51, 36],
		[-48, 41],
		[-47, 43],
		[-45, 45],
		[-43, 47],
		[-41, 48],
		[-39, 49],
		[-37, 50],
		[-34, 51],
		[-32, 52],
		[-30, 52],
		[-27, 53],
		[-25, 53],
		[-23, 54],
		[-20, 54],
		[-18, 54],
		[-13, 54],
		[-12, 54],
		[-10, 54],
		[-7, 53],
		[-4, 52],
		[-1, 50],
		[2, 48],
		[6, 46],
		[9, 44],
		[13, 42],
		[16, 39],
		[21, 36],
		[25, 33],
		[33, 29],
		[38, 26],
		[43, 23],
		[47, 21],
		[53, 19],
		[58, 16],
		[63, 15],
		[68, 13],
		[75, 12],
		[86, 9],
		[92, 9],
		[97, 8],
		[104, 7],
		[109, 7],
		[114, 7],
		[119, 7],
		[125, 7],
		[129, 7],
		[133, 7],
		[135, 7],
		[144, 11],
		[147, 13],
		[148, 14],
		[151, 16],
		[153, 18],
		[155, 20],
		[156, 22],
		[158, 24],
		[158, 26],
		[160, 29],
		[160, 31],
		[161, 34],
		[161, 36],
		[162, 38],
		[162, 41],
		[162, 43],
		[162, 45],
		[162, 47],
		[162, 50],
		[162, 52],
		[162, 54],
		[162, 56],
		[161, 58],
		[160, 60],
		[159, 62],
		[158, 64],
		[157, 66],
		[156, 68],
		[154, 69],
		[153, 71],
		[150, 74],
		[148, 75],
		[146, 76],
		[144, 77],
		[142, 79],
		[138, 80],
		[136, 80],
		[133, 81],
		[129, 82],
		[122, 82],
		[119, 82],
		[115, 82],
		[112, 82],
		[108, 82],
		[106, 82],
		[102, 81],
		[100, 80],
		[97, 79],
		[91, 76],
		[87, 75],
		[83, 73],
		[82, 72],
		[80, 72],
		[78, 71],
		[76, 70],
		[75, 69],
		[74, 69],
		[73, 69],
		[73, 69],
	],
	[
		[-3, 0],
		[-4, 0],
		[-6, 0],
		[-9, 0],
		[-11, 0],
		[-14, 0],
		[-16, 0],
		[-18, 0],
		[-20, 0],
		[-24, 0],
		[-26, 0],
		[-28, 0],
		[-31, 0],
		[-33, 1],
		[-35, 2],
		[-36, 2],
		[-38, 3],
		[-39, 4],
		[-41, 5],
		[-42, 6],
		[-43, 7],
		[-44, 9],
		[-45, 10],
		[-45, 11],
		[-46, 12],
		[-47, 14],
		[-47, 15],
		[-47, 17],
		[-47, 19],
		[-47, 22],
		[-47, 24],
		[-47, 26],
		[-47, 28],
		[-47, 30],
		[-47, 32],
		[-47, 33],
		[-47, 34],
		[-47, 35],
		[-46, 36],
		[-45, 37],
		[-43, 38],
		[-41, 40],
		[-38, 40],
		[-36, 42],
		[-32, 43],
		[-29, 44],
		[-25, 45],
		[-21, 46],
		[-19, 46],
		[-15, 47],
		[-11, 47],
		[-8, 47],
		[-4, 47],
		[-2, 47],
		[1, 47],
		[3, 47],
		[6, 47],
		[8, 47],
		[9, 46],
		[11, 45],
		[12, 44],
		[14, 43],
		[15, 42],
		[17, 41],
		[18, 39],
		[20, 37],
		[22, 35],
		[23, 33],
		[27, 30],
		[29, 27],
		[30, 26],
		[32, 24],
		[36, 19],
		[37, 17],
		[41, 13],
		[44, 11],
		[47, 8],
		[50, 6],
		[53, 4],
		[57, 2],
		[61, -1],
		[65, -3],
		[69, -4],
		[74, -6],
		[79, -7],
		[83, -9],
		[88, -10],
		[93, -11],
		[98, -12],
		[102, -12],
		[108, -13],
		[112, -13],
		[116, -13],
		[121, -13],
		[126, -13],
		[127, -13],
		[132, -11],
		[134, -10],
		[137, -8],
		[140, -7],
		[142, -5],
		[143, -4],
		[144, -2],
		[145, 0],
		[146, 2],
		[146, 4],
		[147, 6],
		[147, 9],
		[147, 11],
		[147, 14],
		[147, 17],
		[147, 19],
		[147, 21],
		[147, 23],
		[145, 25],
		[144, 27],
		[143, 29],
		[142, 31],
		[140, 32],
		[139, 33],
		[137, 35],
		[136, 36],
		[134, 37],
		[132, 38],
		[130, 39],
		[128, 40],
		[126, 41],
		[123, 41],
		[121, 42],
		[118, 42],
		[114, 43],
		[110, 43],
		[106, 43],
		[103, 43],
		[101, 43],
		[97, 43],
		[95, 42],
		[92, 41],
		[89, 40],
		[87, 39],
		[86, 39],
		[84, 38],
		[84, 38],
		[83, 37],
		[82, 36],
		[81, 36],
		[80, 35],
		[79, 34],
		[78, 34],
		[78, 33],
		[77, 33],
		[77, 32],
		[76, 32],
		[76, 32],
		[76, 31],
		[76, 31],
		[76, 31],
	],
	[
		[-4, 0],
		[-7, 0],
		[-10, 0],
		[-14, 0],
		[-16, 0],
		[-20, 0],
		[-24, 0],
		[-29, 0],
		[-31, 0],
		[-36, 0],
		[-39, 0],
		[-42, 1],
		[-45, 3],
		[-46, 3],
		[-51, 7],
		[-51, 7],
		[-55, 10],
		[-56, 12],
		[-57, 13],
		[-58, 14],
		[-59, 16],
		[-60, 18],
		[-60, 19],
		[-61, 22],
		[-61, 25],
		[-61, 32],
		[-61, 36],
		[-60, 37],
		[-57, 44],
		[-55, 46],
		[-54, 48],
		[-52, 50],
		[-50, 51],
		[-46, 53],
		[-45, 54],
		[-41, 55],
		[-38, 55],
		[-34, 56],
		[-33, 56],
		[-26, 56],
		[-25, 56],
		[-19, 56],
		[-15, 56],
		[-12, 56],
		[-9, 55],
		[-5, 54],
		[-2, 52],
		[2, 50],
		[6, 48],
		[9, 45],
		[13, 43],
		[16, 40],
		[20, 37],
		[24, 34],
		[28, 31],
		[32, 27],
		[36, 25],
		[41, 22],
		[47, 19],
		[52, 16],
		[59, 13],
		[65, 10],
		[71, 6],
		[77, 4],
		[83, 1],
		[89, 0],
		[95, -2],
		[101, -4],
		[109, -6],
		[115, -7],
		[121, -7],
		[128, -7],
		[135, -7],
		[141, -7],
		[147, -7],
		[153, -7],
		[160, -7],
		[164, -5],
		[167, -4],
		[170, -3],
		[172, -1],
		[174, 1],
		[176, 2],
		[177, 4],
		[179, 7],
		[181, 12],
		[182, 14],
		[183, 19],
		[183, 21],
		[184, 25],
		[184, 27],
		[184, 33],
		[184, 34],
		[184, 37],
		[181, 43],
		[180, 45],
		[177, 48],
		[176, 49],
		[173, 51],
		[171, 52],
		[168, 53],
		[167, 54],
		[164, 55],
		[162, 56],
		[160, 56],
		[156, 57],
		[155, 57],
		[151, 57],
		[148, 57],
		[144, 57],
		[141, 57],
		[137, 56],
		[133, 54],
		[129, 52],
		[127, 51],
		[123, 48],
		[120, 46],
		[118, 45],
		[116, 43],
		[114, 42],
		[113, 40],
		[112, 40],
		[111, 39],
		[110, 38],
		[110, 38],
		[109, 38],
		[109, 37],
		[109, 37],
		[108, 37],
		[108, 37],
	],
	[
		[-7, 0],
		[-12, 0],
		[-16, 0],
		[-21, 0],
		[-26, 0],
		[-33, 0],
		[-39, 0],
		[-45, 0],
		[-50, 0],
		[-56, 0],
		[-61, 0],
		[-65, 0],
		[-70, 0],
		[-73, 0],
		[-74, 0],
		[-77, 1],
		[-79, 2],
		[-83, 5],
		[-85, 6],
		[-86, 8],
		[-88, 11],
		[-89, 14],
		[-90, 16],
		[-91, 19],
		[-91, 22],
		[-92, 25],
		[-92, 29],
		[-92, 33],
		[-92, 36],
		[-92, 39],
		[-90, 42],
		[-88, 45],
		[-85, 48],
		[-82, 51],
		[-77, 54],
		[-72, 58],
		[-68, 60],
		[-61, 64],
		[-56, 66],
		[-49, 68],
		[-42, 70],
		[-36, 70],
		[-30, 71],
		[-24, 72],
		[-18, 72],
		[-12, 72],
		[-7, 72],
		[-2, 71],
		[4, 69],
		[8, 67],
		[13, 65],
		[18, 63],
		[23, 61],
		[28, 59],
		[33, 56],
		[39, 53],
		[45, 51],
		[50, 49],
		[57, 46],
		[63, 44],
		[69, 43],
		[75, 41],
		[82, 39],
		[87, 38],
		[93, 36],
		[101, 35],
		[107, 34],
		[114, 33],
		[120, 33],
		[128, 33],
		[134, 33],
		[140, 33],
		[145, 33],
		[151, 33],
		[157, 33],
		[163, 33],
		[168, 34],
		[173, 36],
		[179, 38],
		[184, 40],
		[188, 41],
		[190, 42],
		[197, 47],
		[201, 49],
		[204, 52],
		[206, 54],
		[208, 57],
		[210, 59],
		[212, 62],
		[213, 65],
		[215, 70],
		[215, 73],
		[216, 77],
		[216, 79],
		[216, 84],
		[216, 88],
		[215, 90],
		[214, 94],
		[212, 97],
		[211, 99],
		[208, 104],
		[207, 105],
		[204, 108],
		[200, 112],
		[199, 113],
		[195, 117],
		[193, 117],
		[188, 120],
		[186, 121],
		[184, 122],
		[179, 124],
		[176, 125],
		[172, 125],
		[168, 126],
		[166, 126],
		[163, 126],
		[158, 126],
		[154, 126],
		[151, 126],
		[148, 124],
		[144, 122],
		[140, 120],
		[138, 119],
		[135, 116],
		[130, 112],
		[129, 111],
		[126, 107],
		[124, 105],
		[122, 103],
		[121, 101],
		[120, 99],
	],
	[
		[-5, 0],
		[-8, 0],
		[-12, 0],
		[-16, 0],
		[-21, 0],
		[-26, 0],
		[-30, 0],
		[-36, 0],
		[-39, 0],
		[-43, 0],
		[-46, 0],
		[-47, 0],
		[-52, 0],
		[-54, 0],
		[-56, 2],
		[-58, 3],
		[-60, 5],
		[-61, 8],
		[-63, 11],
		[-64, 13],
		[-65, 19],
		[-66, 23],
		[-66, 28],
		[-66, 34],
		[-66, 40],
		[-66, 46],
		[-66, 52],
		[-66, 59],
		[-64, 66],
		[-61, 72],
		[-57, 78],
		[-52, 84],
		[-48, 88],
		[-42, 93],
		[-37, 96],
		[-32, 98],
		[-27, 100],
		[-21, 102],
		[-15, 102],
		[-10, 103],
		[-4, 103],
		[1, 103],
		[8, 103],
		[13, 101],
		[18, 99],
		[24, 96],
		[29, 92],
		[35, 87],
		[41, 82],
		[46, 76],
		[51, 71],
		[56, 63],
		[62, 57],
		[67, 51],
		[72, 46],
		[77, 40],
		[83, 34],
		[88, 29],
		[93, 25],
		[98, 19],
		[104, 15],
		[110, 11],
		[116, 6],
		[121, 3],
		[128, -2],
		[134, -5],
		[139, -8],
		[144, -10],
		[150, -12],
		[155, -13],
		[160, -15],
		[165, -15],
		[172, -15],
		[178, -15],
		[183, -15],
		[188, -15],
		[194, -15],
		[199, -15],
		[203, -14],
		[205, -13],
		[211, -10],
		[214, -9],
		[216, -8],
		[218, -5],
		[223, -1],
		[224, 1],
		[226, 5],
		[228, 9],
		[229, 13],
		[231, 17],
		[231, 20],
		[232, 23],
		[232, 28],
		[233, 34],
		[234, 38],
		[234, 43],
		[234, 48],
		[234, 52],
		[233, 56],
		[233, 59],
		[232, 62],
		[230, 66],
		[228, 69],
		[225, 73],
		[224, 74],
		[222, 77],
		[220, 79],
		[217, 80],
		[213, 83],
		[210, 84],
		[209, 84],
		[204, 85],
		[202, 85],
		[197, 85],
		[195, 85],
		[192, 85],
		[188, 85],
		[186, 85],
		[181, 83],
		[178, 81],
		[175, 78],
		[171, 75],
		[167, 72],
		[163, 67],
		[161, 64],
		[158, 61],
		[155, 58],
		[153, 56],
		[152, 54],
		[151, 53],
		[150, 53],
		[150, 52],
		[150, 52],
		[148, 52],
	],
];
export default unistrokes;
