import { useEffect } from "react";

export default function VoiceListener({
	voiceState,
	predicate,
	stopOnSuccess,
	onSuccess,
}) {
	if (stopOnSuccess === undefined) {
		stopOnSuccess = true;
	}

	useEffect(() => {
		voiceState.checkPermissions();
	}, [voiceState]);

	useEffect(() => {
		function speechHandler(data) {
			if (predicate(data.transcript)) {
				onSuccess && onSuccess();
				if (stopOnSuccess) {
					voiceState.removeSpeachHandler(speechHandler);
				}
			}
		}

		voiceState.addSpeachHandler(speechHandler);

		return () => {
			voiceState.removeSpeachHandler(speechHandler);
		};
	}, [voiceState, predicate, onSuccess, stopOnSuccess]);

	return null;
}
