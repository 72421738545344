import { makeAutoObservable } from "mobx";

export default class VoiceState {
    constructor(audioProcessor) {
        makeAutoObservable(this);

        this._audioProcessor = audioProcessor;
        this._speechHandlers = [];
    }

    haveMicrophonePerm = false;

    checkPermissions() {
        if (navigator.permissions) {
            navigator.permissions
                .query({ name: "microphone" })
                .then((result) => {
                    if (result.state === "granted") {
                        this.haveMicrophonePerm = true;
                    }
                });
        } else {
            this._promptForPermissions();
        }
    }

    _promptForPermissions() {
        if (this.haveMicrophonePerm) {
            return;
        }

        if (navigator.mediaDevices) {
            navigator.mediaDevices
                .getUserMedia({ audio: true })
                .then((stream) => {
                    this.haveMicrophonePerm = true;
                })
                .catch(() => {
                    // nothing
                });
        }
    }

    addSpeachHandler(handler) {
        this._speechHandlers.push(handler);

        if (this._processor) {
            return;
        }
        this._processor = this._audioProcessor.start({
            onTrans: (data) => this.handleSpeech(data),
        });
    }

    removeSpeachHandler(handler) {
        const index = this._speechHandlers.indexOf(handler);
        if (index > -1) {
            this._speechHandlers.splice(index, 1);
        }

        if (this._speechHandlers.length === 0) {
            this.stopSpeechToText();
        }
    }

    stopSpeechToText() {
        if (!this._processor) {
            return;
        }

        this._processor.stop();
        this._processor = null;
    }

    handleSpeech(data) {
        this._speechHandlers.forEach((handler) => handler(data));
    }
}
