const unistrokes = [
	[
		[-1, -1],
		[-1, -2],
		[-1, -4],
		[-1, -7],
		[-1, -10],
		[-1, -18],
		[-1, -25],
		[1, -32],
		[2, -36],
		[5, -44],
		[8, -51],
		[16, -64],
		[22, -71],
		[29, -79],
		[36, -85],
		[44, -93],
		[54, -99],
		[65, -106],
		[70, -108],
		[80, -113],
		[91, -116],
		[102, -120],
		[112, -123],
		[123, -124],
		[133, -125],
		[143, -126],
		[153, -126],
		[163, -126],
		[172, -126],
		[182, -125],
		[198, -120],
		[203, -117],
		[212, -112],
		[220, -107],
		[228, -101],
		[235, -96],
		[242, -90],
		[248, -85],
		[253, -79],
		[258, -74],
		[262, -69],
		[265, -64],
		[268, -58],
		[271, -52],
		[274, -46],
		[276, -40],
		[278, -34],
		[280, -26],
		[281, -20],
		[282, -14],
		[283, -8],
		[283, -1],
		[283, 6],
		[283, 14],
		[283, 22],
		[283, 30],
		[283, 38],
		[283, 44],
		[281, 50],
		[279, 56],
		[277, 61],
		[274, 68],
		[271, 73],
		[266, 80],
		[262, 86],
		[257, 93],
		[252, 99],
		[246, 105],
		[241, 110],
		[234, 116],
		[228, 122],
		[222, 126],
		[217, 130],
		[211, 134],
		[206, 138],
		[200, 141],
		[194, 143],
		[187, 146],
		[180, 148],
		[172, 150],
		[164, 151],
		[157, 153],
		[149, 153],
		[142, 154],
		[136, 154],
		[129, 155],
		[123, 155],
		[116, 155],
		[110, 155],
		[103, 154],
		[96, 152],
		[89, 150],
		[81, 146],
		[75, 143],
		[68, 140],
		[63, 137],
		[58, 134],
		[53, 130],
		[48, 126],
		[44, 122],
		[40, 117],
		[36, 112],
		[32, 106],
		[28, 100],
		[24, 93],
		[21, 87],
		[18, 81],
		[15, 76],
		[13, 71],
		[11, 65],
		[9, 61],
		[7, 56],
		[6, 50],
		[4, 46],
		[3, 43],
		[3, 39],
		[2, 35],
		[2, 28],
		[2, 27],
		[2, 20],
		[2, 16],
		[3, 12],
		[5, 7],
		[7, 3],
		[8, 0],
		[10, -4],
		[12, -7],
		[14, -10],
		[15, -13],
		[16, -15],
		[18, -16],
		[19, -18],
		[20, -20],
		[22, -22],
		[23, -24],
		[24, -25],
		[26, -26],
		[27, -26],
	],
	[
		[0, -3],
		[-1, -5],
		[-3, -9],
		[-5, -13],
		[-8, -17],
		[-11, -22],
		[-15, -25],
		[-19, -29],
		[-24, -34],
		[-29, -37],
		[-33, -40],
		[-46, -48],
		[-48, -49],
		[-56, -53],
		[-63, -56],
		[-70, -58],
		[-76, -60],
		[-81, -62],
		[-87, -63],
		[-93, -64],
		[-98, -64],
		[-103, -65],
		[-106, -65],
		[-113, -65],
		[-115, -65],
		[-122, -65],
		[-123, -65],
		[-129, -64],
		[-132, -62],
		[-136, -60],
		[-139, -58],
		[-143, -54],
		[-148, -51],
		[-152, -48],
		[-156, -44],
		[-160, -41],
		[-164, -37],
		[-168, -34],
		[-172, -30],
		[-175, -26],
		[-178, -22],
		[-181, -18],
		[-183, -13],
		[-185, -8],
		[-188, -3],
		[-189, 3],
		[-191, 9],
		[-192, 15],
		[-193, 22],
		[-194, 28],
		[-194, 36],
		[-194, 44],
		[-194, 51],
		[-194, 60],
		[-194, 67],
		[-193, 76],
		[-190, 83],
		[-188, 90],
		[-186, 98],
		[-182, 106],
		[-179, 113],
		[-175, 120],
		[-171, 125],
		[-166, 132],
		[-161, 138],
		[-155, 144],
		[-150, 148],
		[-143, 154],
		[-137, 158],
		[-131, 162],
		[-123, 165],
		[-115, 169],
		[-107, 172],
		[-98, 174],
		[-89, 177],
		[-80, 179],
		[-70, 180],
		[-61, 181],
		[-53, 182],
		[-44, 182],
		[-36, 182],
		[-28, 182],
		[-21, 182],
		[-14, 182],
		[-6, 182],
		[0, 180],
		[7, 179],
		[14, 176],
		[20, 174],
		[26, 172],
		[33, 168],
		[38, 165],
		[43, 161],
		[48, 158],
		[52, 154],
		[56, 150],
		[59, 145],
		[62, 141],
		[65, 136],
		[66, 133],
		[68, 128],
		[69, 126],
		[71, 120],
		[72, 118],
		[73, 112],
		[73, 107],
		[74, 102],
		[74, 97],
		[74, 91],
		[74, 86],
		[74, 81],
		[74, 76],
		[73, 74],
		[73, 69],
		[72, 66],
		[71, 62],
		[69, 56],
		[67, 52],
		[65, 49],
		[63, 44],
		[60, 40],
		[57, 34],
		[53, 28],
		[50, 23],
		[47, 19],
		[46, 17],
		[43, 14],
		[38, 7],
		[35, 4],
		[32, 1],
		[29, -2],
		[27, -5],
		[25, -6],
		[22, -8],
		[18, -12],
		[14, -15],
		[11, -17],
		[8, -18],
		[4, -21],
		[0, -23],
		[-3, -25],
		[-7, -28],
		[-12, -31],
		[-16, -33],
		[-18, -35],
		[-21, -36],
		[-23, -37],
		[-26, -39],
		[-28, -39],
		[-30, -40],
		[-32, -40],
		[-33, -40],
	],
	[
		[-3, 0],
		[-5, 0],
		[-7, 0],
		[-10, -1],
		[-14, -1],
		[-18, -2],
		[-23, -2],
		[-30, -4],
		[-36, -4],
		[-43, -5],
		[-51, -6],
		[-59, -6],
		[-66, -7],
		[-74, -7],
		[-81, -7],
		[-88, -7],
		[-97, -7],
		[-103, -7],
		[-109, -7],
		[-117, -7],
		[-123, -7],
		[-129, -6],
		[-135, -4],
		[-141, -1],
		[-147, 1],
		[-152, 3],
		[-157, 6],
		[-162, 8],
		[-167, 11],
		[-172, 14],
		[-176, 17],
		[-180, 20],
		[-184, 24],
		[-187, 29],
		[-190, 34],
		[-194, 40],
		[-196, 45],
		[-198, 51],
		[-200, 58],
		[-202, 65],
		[-203, 72],
		[-204, 80],
		[-204, 89],
		[-205, 97],
		[-205, 107],
		[-205, 117],
		[-205, 126],
		[-205, 135],
		[-203, 143],
		[-200, 152],
		[-197, 160],
		[-194, 168],
		[-190, 176],
		[-186, 183],
		[-180, 190],
		[-174, 196],
		[-168, 203],
		[-161, 208],
		[-153, 214],
		[-144, 220],
		[-136, 224],
		[-127, 228],
		[-118, 232],
		[-109, 237],
		[-100, 240],
		[-93, 243],
		[-84, 246],
		[-76, 249],
		[-67, 251],
		[-60, 253],
		[-51, 254],
		[-43, 255],
		[-35, 256],
		[-26, 256],
		[-19, 256],
		[-11, 256],
		[-4, 256],
		[2, 255],
		[7, 253],
		[13, 250],
		[18, 247],
		[22, 244],
		[26, 241],
		[30, 237],
		[33, 234],
		[36, 230],
		[39, 225],
		[41, 221],
		[43, 216],
		[45, 210],
		[47, 204],
		[49, 198],
		[50, 192],
		[52, 185],
		[54, 178],
		[56, 172],
		[57, 164],
		[58, 157],
		[58, 150],
		[59, 143],
		[60, 135],
		[60, 128],
		[60, 121],
		[60, 115],
		[60, 107],
		[60, 101],
		[60, 93],
		[60, 87],
		[59, 81],
		[56, 74],
		[55, 68],
		[52, 62],
		[49, 55],
		[46, 49],
		[42, 42],
		[39, 36],
		[36, 30],
		[34, 28],
		[32, 24],
		[30, 21],
		[28, 18],
		[26, 16],
		[23, 13],
		[20, 11],
		[17, 10],
		[13, 8],
		[8, 6],
		[4, 5],
		[0, 4],
		[-6, 3],
		[-10, 1],
		[-15, 0],
		[-17, -1],
		[-23, -2],
		[-27, -3],
		[-31, -4],
		[-35, -5],
		[-39, -6],
		[-41, -6],
		[-44, -6],
		[-48, -6],
		[-50, -6],
	],
];
export default unistrokes;
