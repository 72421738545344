import DollarRecognizer, { Point } from "./GestureDetector";

import Hammer from "hammerjs";
import { useEffect } from "react";

const GESTURE_THREASHOLD = 0.6;

export default function GestureRecognizer({ onGesture, gestures }) {
	useEffect(() => {
		const manager = new Hammer.Manager(document.body);
		manager.add(
			new Hammer.Pan({ direction: Hammer.DIRECTION_ALL, threshold: 0 })
		);

		const regoc = new DollarRecognizer();
		let points;
		let pointsStr;

		manager.on("panstart", (evt) => {
			points = [];
			pointsStr = "";
		});
		manager.on("panmove", (evt) => {
			// console.log(evt.deltaX, evt.deltaY);
			pointsStr += `[${evt.deltaX}, ${evt.deltaY}],`;
			points.push(new Point(evt.deltaX, evt.deltaY));
		});
		manager.on("panend", (evt) => {
			// console.log(points);
			if (!points || points.length === 0) {
				return;
			}
			console.log(`[${pointsStr}]`);
			const result = regoc.Recognize(points, true);
			console.log(result);
			if (
				onGesture &&
				result.Score > GESTURE_THREASHOLD &&
				(gestures === undefined || gestures.includes(result.Name))
			) {
				onGesture({
					name: result.Name,
					score: result.Score,
				});
			}
		});

		return () => {
			manager.destroy();
		};
	}, [gestures, onGesture]);

	return null;
}
