const unistrokes = [
	[
		[-3, 0],
		[-6, 0],
		[-8, 0],
		[-11, 0],
		[-15, 0],
		[-19, 0],
		[-23, 1],
		[-28, 1],
		[-34, 2],
		[-40, 3],
		[-48, 4],
		[-55, 5],
		[-62, 5],
		[-70, 6],
		[-77, 7],
		[-84, 7],
		[-92, 8],
		[-99, 9],
		[-107, 9],
		[-115, 9],
		[-122, 9],
		[-128, 9],
		[-136, 9],
		[-142, 9],
		[-147, 9],
		[-152, 7],
		[-157, 6],
		[-161, 4],
		[-165, 2],
		[-169, -1],
		[-172, -3],
		[-175, -6],
		[-178, -10],
		[-181, -15],
		[-183, -19],
		[-185, -25],
		[-187, -32],
		[-188, -39],
		[-189, -46],
		[-189, -54],
		[-189, -62],
		[-189, -70],
		[-189, -79],
		[-189, -86],
		[-188, -94],
		[-185, -101],
		[-183, -106],
		[-180, -112],
		[-178, -117],
		[-175, -121],
		[-172, -125],
		[-168, -129],
		[-165, -132],
		[-162, -136],
		[-158, -139],
		[-155, -142],
		[-153, -143],
		[-150, -145],
		[-144, -149],
		[-143, -149],
		[-140, -151],
		[-137, -152],
		[-134, -153],
		[-132, -153],
		[-129, -154],
		[-127, -154],
		[-125, -154],
		[-122, -154],
		[-120, -154],
		[-117, -154],
		[-114, -152],
		[-112, -151],
		[-111, -150],
		[-108, -148],
		[-105, -146],
		[-103, -144],
		[-101, -142],
		[-100, -141],
		[-98, -139],
		[-96, -137],
		[-96, -135],
		[-94, -132],
		[-93, -129],
		[-92, -126],
		[-91, -123],
		[-91, -118],
		[-90, -115],
		[-90, -109],
		[-90, -105],
		[-89, -100],
		[-89, -98],
		[-89, -90],
		[-89, -88],
		[-89, -81],
		[-89, -77],
		[-89, -73],
		[-89, -69],
		[-90, -64],
		[-90, -59],
		[-91, -53],
		[-92, -48],
		[-93, -41],
		[-94, -35],
		[-95, -28],
		[-97, -22],
		[-98, -15],
		[-100, -8],
		[-102, 0],
		[-103, 9],
		[-105, 16],
		[-107, 24],
		[-109, 32],
		[-110, 38],
		[-111, 43],
		[-112, 49],
		[-112, 51],
		[-114, 59],
		[-114, 63],
		[-114, 65],
		[-115, 72],
		[-115, 73],
		[-115, 76],
		[-116, 79],
		[-116, 80],
		[-116, 82],
		[-116, 83],
		[-116, 83],
		[-116, 84],
		[-116, 84],
		[-116, 84],
		[-116, 84],
		[-116, 84],
		[-116, 84],
	],
	[
		[0, -4],
		[0, -8],
		[0, -12],
		[0, -17],
		[0, -23],
		[0, -31],
		[0, -44],
		[0, -54],
		[0, -58],
		[0, -66],
		[0, -75],
		[0, -85],
		[0, -92],
		[0, -99],
		[0, -105],
		[0, -112],
		[0, -117],
		[0, -122],
		[0, -127],
		[0, -129],
		[-2, -137],
		[-3, -142],
		[-4, -145],
		[-6, -149],
		[-8, -152],
		[-11, -156],
		[-12, -157],
		[-15, -162],
		[-19, -165],
		[-21, -168],
		[-24, -171],
		[-28, -173],
		[-29, -175],
		[-32, -177],
		[-38, -180],
		[-39, -181],
		[-44, -183],
		[-47, -185],
		[-51, -186],
		[-55, -186],
		[-59, -187],
		[-63, -187],
		[-67, -187],
		[-72, -187],
		[-76, -187],
		[-78, -187],
		[-82, -187],
		[-84, -187],
		[-87, -186],
		[-89, -185],
		[-91, -184],
		[-93, -182],
		[-94, -181],
		[-95, -179],
		[-96, -177],
		[-98, -175],
		[-99, -172],
		[-99, -170],
		[-100, -166],
		[-101, -162],
		[-102, -159],
		[-102, -154],
		[-103, -150],
		[-103, -145],
		[-103, -141],
		[-103, -137],
		[-103, -132],
		[-103, -130],
		[-103, -126],
		[-103, -122],
		[-103, -119],
		[-103, -116],
		[-102, -113],
		[-100, -109],
		[-99, -106],
		[-97, -103],
		[-94, -100],
		[-92, -97],
		[-89, -95],
		[-87, -92],
		[-85, -90],
		[-80, -86],
		[-79, -85],
		[-76, -82],
		[-73, -80],
		[-68, -78],
		[-67, -77],
		[-62, -75],
		[-59, -74],
		[-55, -73],
		[-51, -72],
		[-47, -72],
		[-42, -71],
		[-36, -70],
		[-31, -70],
		[-26, -70],
		[-19, -70],
		[-14, -70],
		[-8, -70],
		[-2, -70],
		[5, -70],
		[11, -70],
		[17, -70],
		[22, -70],
		[28, -71],
		[34, -72],
		[39, -72],
		[44, -73],
		[49, -74],
		[55, -75],
		[60, -76],
		[64, -77],
		[67, -78],
		[70, -79],
		[73, -80],
		[77, -81],
		[80, -81],
		[82, -82],
		[85, -82],
	],
	[
		[-4, 0],
		[-6, 0],
		[-8, 0],
		[-11, 0],
		[-15, 0],
		[-17, 0],
		[-21, 0],
		[-29, 0],
		[-37, 0],
		[-41, 0],
		[-43, 0],
		[-46, 0],
		[-53, 0],
		[-60, 0],
		[-66, 0],
		[-68, -1],
		[-71, -2],
		[-74, -3],
		[-76, -4],
		[-78, -5],
		[-80, -7],
		[-83, -9],
		[-85, -12],
		[-86, -13],
		[-88, -15],
		[-90, -18],
		[-91, -20],
		[-92, -23],
		[-93, -25],
		[-95, -28],
		[-97, -31],
		[-98, -35],
		[-99, -38],
		[-99, -42],
		[-99, -46],
		[-99, -52],
		[-99, -57],
		[-99, -62],
		[-99, -68],
		[-98, -72],
		[-96, -76],
		[-94, -81],
		[-92, -84],
		[-90, -87],
		[-87, -90],
		[-84, -93],
		[-81, -96],
		[-78, -97],
		[-76, -98],
		[-71, -100],
		[-70, -100],
		[-66, -101],
		[-63, -102],
		[-61, -102],
		[-58, -102],
		[-56, -102],
		[-54, -102],
		[-52, -102],
		[-50, -101],
		[-49, -100],
		[-48, -99],
		[-46, -98],
		[-45, -96],
		[-44, -93],
		[-42, -90],
		[-41, -88],
		[-40, -84],
		[-39, -81],
		[-38, -76],
		[-37, -72],
		[-36, -67],
		[-35, -62],
		[-34, -58],
		[-33, -54],
		[-33, -52],
		[-32, -45],
		[-32, -41],
		[-32, -36],
		[-31, -31],
		[-31, -26],
		[-31, -21],
		[-31, -15],
		[-31, -9],
		[-31, -3],
		[-31, 2],
		[-31, 7],
		[-31, 10],
		[-31, 18],
		[-31, 20],
		[-31, 24],
		[-31, 28],
		[-32, 33],
		[-32, 36],
		[-32, 39],
		[-32, 42],
		[-33, 45],
		[-33, 48],
		[-33, 52],
		[-33, 55],
		[-33, 57],
		[-33, 60],
		[-33, 62],
		[-33, 64],
		[-33, 65],
		[-33, 66],
		[-33, 67],
		[-33, 68],
		[-33, 68],
		[-33, 69],
		[-33, 69],
		[-33, 69],
	],
	[
		[2, 0],
		[4, 0],
		[10, 0],
		[13, 0],
		[21, 0],
		[29, 0],
		[33, 0],
		[41, 0],
		[45, 0],
		[46, 0],
		[51, 0],
		[52, 0],
		[54, 0],
		[57, 0],
		[60, 0],
		[62, 0],
		[64, 0],
		[69, -2],
		[72, -4],
		[74, -5],
		[76, -7],
		[79, -9],
		[81, -10],
		[83, -12],
		[87, -16],
		[89, -18],
		[92, -22],
		[94, -26],
		[96, -31],
		[96, -33],
		[97, -38],
		[98, -43],
		[98, -49],
		[98, -53],
		[98, -56],
		[98, -60],
		[98, -63],
		[98, -65],
		[96, -67],
		[95, -69],
		[93, -70],
		[89, -73],
		[88, -74],
		[85, -75],
		[82, -76],
		[76, -78],
		[69, -79],
		[66, -79],
		[63, -79],
		[57, -79],
		[55, -79],
		[52, -79],
		[47, -79],
		[45, -78],
		[43, -77],
		[42, -76],
		[40, -74],
		[37, -72],
		[36, -70],
		[34, -65],
		[33, -61],
		[30, -51],
		[30, -47],
		[29, -33],
		[29, -25],
		[29, -16],
		[29, 9],
		[29, 22],
		[29, 36],
		[30, 47],
		[32, 68],
		[34, 80],
		[35, 86],
		[36, 93],
		[37, 97],
		[37, 102],
	],
	[
		[7, 0],
		[11, 0],
		[15, 0],
		[21, 0],
		[26, 0],
		[34, 0],
		[40, 0],
		[49, 0],
		[56, 0],
		[69, 0],
		[76, 0],
		[79, 0],
		[84, 0],
		[87, 0],
		[90, -1],
		[92, -2],
		[95, -4],
		[97, -5],
		[98, -7],
		[100, -8],
		[102, -12],
		[102, -14],
		[103, -15],
		[103, -18],
		[103, -19],
		[103, -20],
		[103, -20],
		[103, -21],
		[103, -22],
		[102, -22],
		[97, -26],
		[91, -28],
		[83, -31],
		[74, -34],
		[67, -35],
		[64, -35],
		[57, -35],
		[54, -35],
		[45, -35],
		[37, -35],
		[30, -35],
		[26, -33],
		[22, -31],
		[21, -28],
		[20, -27],
		[19, -25],
		[18, -23],
		[17, -19],
		[17, -11],
		[17, 4],
		[18, 16],
		[20, 23],
		[27, 47],
		[31, 60],
		[34, 70],
	],
	[
		[2, 0],
		[4, 0],
		[8, 0],
		[11, 0],
		[15, 0],
		[19, 0],
		[23, 0],
		[28, 0],
		[34, 0],
		[38, 0],
		[50, 0],
		[54, 0],
		[55, 0],
		[64, 0],
		[70, 0],
		[74, -2],
		[78, -4],
		[79, -5],
		[81, -7],
		[82, -8],
		[83, -10],
		[84, -12],
		[85, -13],
		[86, -16],
		[86, -21],
		[86, -25],
		[86, -28],
		[86, -30],
		[86, -33],
		[85, -36],
		[84, -39],
		[83, -41],
		[82, -44],
		[81, -46],
		[80, -47],
		[78, -49],
		[76, -51],
		[74, -53],
		[73, -54],
		[71, -55],
		[69, -56],
		[68, -57],
		[66, -58],
		[62, -59],
		[60, -60],
		[58, -60],
		[56, -60],
		[54, -61],
		[53, -61],
		[51, -61],
		[50, -61],
		[49, -61],
		[46, -61],
		[44, -61],
		[42, -61],
		[40, -61],
		[39, -60],
		[38, -60],
		[38, -59],
		[37, -58],
		[36, -57],
		[34, -54],
		[34, -52],
		[33, -50],
		[33, -48],
		[32, -45],
		[32, -42],
		[31, -38],
		[31, -30],
		[31, -25],
		[31, -20],
		[31, -8],
		[31, 0],
		[31, 7],
		[33, 23],
		[37, 39],
		[39, 50],
		[41, 56],
		[42, 61],
		[43, 66],
		[44, 70],
		[45, 74],
		[46, 77],
		[47, 83],
		[48, 87],
	],
	[
		[3, 0],
		[6, 0],
		[9, 0],
		[12, 0],
		[16, 0],
		[20, 0],
		[24, 0],
		[29, 0],
		[34, 0],
		[40, 0],
		[45, 0],
		[50, 0],
		[55, 0],
		[57, 0],
		[63, 0],
		[65, 0],
		[69, 0],
		[72, 0],
		[75, -2],
		[77, -3],
		[79, -4],
		[80, -5],
		[82, -7],
		[84, -8],
		[85, -10],
		[87, -12],
		[88, -13],
		[89, -15],
		[90, -17],
		[91, -19],
		[93, -22],
		[94, -23],
		[95, -26],
		[96, -28],
		[96, -30],
		[97, -32],
		[97, -34],
		[98, -37],
		[98, -40],
		[98, -42],
		[98, -44],
		[98, -47],
		[98, -50],
		[98, -52],
		[98, -54],
		[98, -57],
		[97, -61],
		[96, -63],
		[94, -66],
		[92, -69],
		[90, -72],
		[87, -75],
		[84, -79],
		[81, -82],
		[78, -85],
		[74, -89],
		[70, -92],
		[67, -95],
		[65, -97],
		[62, -99],
		[59, -100],
		[56, -102],
		[53, -103],
		[51, -104],
		[48, -105],
		[46, -105],
		[44, -105],
		[42, -105],
		[40, -105],
		[39, -105],
		[37, -105],
		[35, -104],
		[34, -102],
		[32, -101],
		[31, -99],
		[30, -96],
		[28, -92],
		[27, -88],
		[26, -83],
		[26, -78],
		[24, -65],
		[23, -59],
		[23, -51],
		[23, -41],
		[23, -34],
		[23, -25],
		[23, -18],
		[23, -11],
		[23, -5],
		[23, 3],
		[23, 11],
		[23, 18],
		[23, 27],
		[23, 34],
		[24, 44],
		[25, 51],
		[27, 59],
		[28, 66],
		[30, 74],
		[31, 81],
		[32, 88],
		[33, 95],
		[34, 102],
		[35, 108],
		[36, 111],
		[36, 118],
		[37, 120],
		[38, 124],
		[38, 127],
		[39, 131],
		[39, 133],
		[40, 136],
		[40, 138],
	],
];
export default unistrokes;
