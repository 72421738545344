const unistrokes = [
	[
		[0, -5],
		[0, -9],
		[1, -15],
		[5, -24],
		[7, -33],
		[11, -45],
		[15, -58],
		[21, -72],
		[23, -78],
		[27, -92],
		[32, -103],
		[36, -113],
		[40, -122],
		[44, -129],
		[45, -132],
		[48, -137],
		[49, -140],
		[51, -142],
		[52, -144],
		[53, -145],
		[53, -145],
		[54, -145],
		[54, -146],
		[55, -146],
		[55, -146],
		[55, -146],
		[56, -146],
		[56, -146],
		[57, -144],
		[59, -141],
		[61, -136],
		[64, -130],
		[67, -123],
		[71, -114],
		[76, -102],
		[80, -90],
		[85, -77],
		[89, -66],
		[94, -55],
		[95, -51],
		[99, -43],
		[102, -37],
		[104, -32],
		[105, -29],
		[107, -27],
		[108, -25],
		[109, -24],
		[109, -24],
		[109, -23],
		[110, -23],
		[110, -23],
		[110, -23],
		[111, -23],
		[111, -23],
		[112, -25],
		[113, -29],
		[115, -35],
		[117, -43],
		[120, -53],
		[123, -65],
		[127, -77],
		[130, -89],
		[134, -101],
		[136, -109],
		[138, -113],
		[140, -119],
		[142, -124],
		[144, -129],
		[145, -132],
		[147, -135],
		[148, -137],
		[149, -139],
		[149, -140],
		[150, -140],
		[150, -140],
		[151, -140],
		[151, -140],
		[151, -140],
		[152, -140],
		[152, -139],
		[154, -136],
		[156, -133],
		[159, -127],
		[162, -121],
		[165, -114],
		[169, -105],
		[172, -95],
		[176, -85],
		[180, -73],
		[184, -62],
		[185, -59],
		[188, -50],
		[191, -44],
		[192, -38],
		[194, -35],
		[195, -32],
		[196, -31],
		[196, -29],
		[196, -29],
		[197, -29],
		[197, -28],
		[198, -28],
		[198, -28],
		[198, -29],
		[199, -31],
		[201, -34],
		[203, -39],
		[207, -50],
		[212, -62],
		[216, -76],
		[224, -94],
		[230, -113],
		[236, -135],
		[244, -159],
		[252, -182],
		[255, -192],
		[262, -210],
		[268, -225],
		[273, -238],
		[275, -242],
		[278, -251],
		[281, -256],
		[283, -260],
		[284, -262],
		[285, -263],
		[286, -264],
		[286, -264],
	],
	[
		[0, 0],
		[0, -1],
		[0, -4],
		[1, -8],
		[2, -13],
		[4, -18],
		[7, -24],
		[10, -30],
		[13, -36],
		[17, -44],
		[21, -53],
		[24, -59],
		[28, -67],
		[31, -73],
		[35, -79],
		[38, -85],
		[40, -87],
		[43, -92],
		[44, -95],
		[46, -98],
		[47, -100],
		[48, -101],
		[49, -102],
		[49, -102],
		[49, -102],
		[50, -102],
		[50, -102],
		[51, -102],
		[51, -101],
		[53, -99],
		[56, -94],
		[60, -88],
		[65, -80],
		[70, -72],
		[77, -64],
		[82, -54],
		[89, -46],
		[94, -38],
		[99, -32],
		[102, -29],
		[105, -25],
		[109, -21],
		[111, -17],
		[114, -15],
		[115, -13],
		[117, -12],
		[118, -11],
		[118, -11],
		[119, -11],
		[119, -11],
		[120, -11],
		[120, -11],
		[122, -12],
		[122, -14],
		[124, -17],
		[125, -21],
		[127, -25],
		[129, -30],
		[131, -36],
		[134, -41],
		[137, -47],
		[139, -53],
		[142, -59],
		[144, -67],
		[147, -73],
		[149, -76],
		[151, -82],
		[153, -87],
		[155, -91],
		[157, -95],
		[158, -97],
		[159, -98],
		[159, -99],
		[160, -100],
		[160, -100],
		[161, -100],
		[161, -100],
		[161, -100],
		[162, -100],
		[163, -98],
		[164, -95],
		[166, -90],
		[169, -84],
		[173, -78],
		[176, -70],
		[181, -61],
		[186, -52],
		[190, -42],
		[195, -33],
		[198, -29],
		[201, -23],
		[204, -17],
		[207, -13],
		[207, -12],
		[210, -9],
		[211, -8],
		[211, -7],
		[211, -7],
		[212, -7],
		[212, -7],
		[213, -7],
		[213, -7],
		[213, -7],
		[214, -7],
		[215, -7],
		[217, -10],
		[218, -11],
		[221, -15],
		[224, -20],
		[227, -25],
		[230, -31],
		[234, -38],
		[243, -56],
		[247, -65],
		[251, -73],
		[254, -80],
		[256, -83],
		[259, -89],
		[261, -93],
		[263, -96],
		[263, -98],
	],
	[
		[2, -27],
		[5, -39],
		[9, -53],
		[12, -68],
		[15, -75],
		[18, -89],
		[22, -104],
		[26, -116],
		[29, -126],
		[32, -137],
		[33, -139],
		[34, -144],
		[36, -148],
		[37, -151],
		[38, -151],
		[38, -152],
		[39, -153],
		[39, -153],
		[39, -153],
		[40, -153],
		[40, -153],
		[40, -152],
		[42, -150],
		[45, -147],
		[48, -143],
		[53, -136],
		[57, -130],
		[63, -122],
		[68, -114],
		[74, -106],
		[79, -98],
		[84, -89],
		[89, -80],
		[94, -70],
		[98, -62],
		[100, -59],
		[103, -53],
		[105, -49],
		[107, -47],
		[108, -45],
		[109, -44],
		[109, -44],
		[109, -43],
		[110, -43],
		[110, -43],
		[110, -43],
		[111, -43],
		[111, -45],
		[113, -48],
		[114, -52],
		[115, -58],
		[117, -63],
		[120, -69],
		[121, -73],
		[122, -78],
		[126, -89],
		[128, -94],
		[129, -96],
		[133, -104],
		[135, -109],
		[136, -112],
		[138, -116],
		[139, -119],
		[141, -122],
		[142, -124],
		[143, -126],
		[144, -127],
		[144, -127],
		[144, -127],
		[145, -127],
		[145, -127],
		[145, -127],
		[146, -126],
		[147, -122],
		[149, -117],
		[154, -109],
		[158, -100],
		[163, -90],
		[169, -78],
		[174, -67],
		[180, -58],
		[181, -55],
		[186, -47],
		[189, -42],
		[191, -38],
		[193, -35],
		[194, -32],
		[195, -32],
		[195, -31],
		[196, -30],
		[196, -30],
		[196, -30],
		[197, -30],
		[197, -30],
		[198, -32],
		[199, -35],
		[201, -38],
		[204, -43],
		[208, -51],
		[212, -58],
		[217, -68],
		[222, -77],
		[228, -86],
		[232, -96],
		[235, -100],
		[238, -106],
		[241, -111],
		[243, -115],
		[245, -118],
		[246, -119],
		[246, -120],
		[247, -120],
	],
	[
		[0, -5],
		[0, -8],
		[0, -13],
		[3, -24],
		[5, -35],
		[8, -45],
		[8, -50],
		[11, -60],
		[14, -69],
		[16, -75],
		[16, -76],
		[18, -81],
		[19, -84],
		[21, -87],
		[21, -88],
		[22, -90],
		[22, -90],
		[23, -90],
		[23, -89],
		[24, -84],
		[25, -77],
		[28, -68],
		[32, -58],
		[36, -47],
		[41, -35],
		[46, -22],
		[51, -13],
		[56, -2],
		[61, 6],
		[63, 8],
		[68, 14],
		[71, 18],
		[75, 21],
		[78, 22],
		[80, 24],
		[82, 24],
		[86, 24],
		[88, 24],
		[91, 20],
		[95, 15],
		[98, 8],
		[101, 2],
		[104, -5],
		[106, -12],
		[110, -20],
		[112, -26],
		[114, -32],
		[117, -38],
		[118, -41],
		[120, -46],
		[122, -50],
		[124, -53],
		[125, -55],
		[126, -56],
		[127, -57],
		[128, -58],
		[128, -59],
		[128, -59],
		[129, -59],
		[129, -59],
		[129, -59],
		[131, -56],
		[135, -49],
		[137, -44],
		[141, -35],
		[146, -25],
		[151, -16],
		[155, -7],
		[160, 1],
		[162, 3],
		[165, 9],
		[169, 13],
		[171, 16],
		[174, 19],
		[176, 20],
		[178, 21],
		[181, 21],
		[183, 22],
		[186, 22],
		[189, 22],
		[194, 22],
		[196, 22],
		[201, 20],
		[205, 16],
		[210, 12],
		[214, 6],
		[218, -3],
		[228, -18],
		[233, -32],
		[238, -47],
		[244, -63],
		[247, -71],
		[252, -87],
		[258, -101],
		[262, -115],
		[264, -118],
		[268, -126],
		[271, -132],
	],
	[
		[4, -13],
		[10, -25],
		[12, -31],
		[16, -39],
		[17, -43],
		[21, -51],
		[25, -57],
		[28, -65],
		[32, -69],
		[33, -71],
		[36, -74],
		[39, -78],
		[41, -80],
		[44, -81],
		[46, -83],
		[47, -83],
		[48, -84],
		[49, -84],
		[50, -84],
		[50, -84],
		[51, -84],
		[53, -83],
		[55, -80],
		[58, -76],
		[63, -67],
		[69, -60],
		[72, -56],
		[78, -46],
		[84, -38],
		[90, -30],
		[95, -20],
		[101, -12],
		[106, -4],
		[111, 3],
		[115, 9],
		[116, 12],
		[119, 15],
		[121, 18],
		[122, 21],
		[123, 22],
		[124, 22],
		[124, 23],
		[125, 23],
		[126, 23],
		[127, 23],
		[129, 22],
		[131, 19],
		[134, 15],
		[138, 11],
		[141, 6],
		[146, -1],
		[150, -7],
		[155, -14],
		[159, -21],
		[164, -29],
		[169, -38],
		[175, -46],
		[179, -54],
		[184, -62],
		[188, -69],
		[192, -74],
		[196, -79],
		[197, -81],
		[200, -85],
		[202, -87],
		[204, -90],
		[206, -91],
		[207, -92],
		[207, -92],
		[207, -92],
		[208, -92],
		[208, -92],
		[208, -92],
		[208, -92],
		[209, -91],
		[211, -88],
		[215, -80],
		[219, -73],
		[222, -68],
		[226, -59],
		[231, -51],
		[235, -43],
		[236, -41],
		[238, -36],
		[241, -32],
		[243, -29],
		[245, -26],
		[246, -24],
		[248, -23],
		[249, -21],
		[250, -20],
		[251, -20],
		[252, -19],
		[253, -19],
		[253, -18],
		[254, -18],
		[255, -18],
		[255, -18],
		[257, -18],
		[257, -18],
		[259, -18],
		[261, -20],
		[264, -23],
		[267, -26],
		[270, -30],
		[275, -35],
		[279, -41],
		[284, -47],
		[289, -54],
		[294, -61],
		[300, -68],
		[304, -74],
		[308, -80],
		[313, -85],
		[315, -87],
		[318, -91],
		[322, -95],
		[324, -98],
		[326, -100],
		[327, -102],
		[328, -103],
		[329, -103],
		[330, -104],
		[330, -104],
		[330, -104],
	],
	[
		[2, -11],
		[3, -14],
		[5, -18],
		[7, -23],
		[9, -28],
		[11, -32],
		[13, -39],
		[16, -44],
		[18, -49],
		[20, -53],
		[22, -59],
		[24, -62],
		[26, -66],
		[27, -68],
		[29, -72],
		[31, -75],
		[33, -78],
		[34, -81],
		[36, -82],
		[37, -84],
		[38, -85],
		[38, -86],
		[39, -86],
		[39, -86],
		[39, -87],
		[39, -87],
		[40, -87],
		[40, -86],
		[40, -85],
		[41, -83],
		[43, -80],
		[45, -76],
		[51, -68],
		[55, -60],
		[58, -55],
		[65, -46],
		[69, -38],
		[74, -29],
		[78, -22],
		[82, -16],
		[85, -10],
		[87, -8],
		[89, -4],
		[92, -1],
		[94, 2],
		[96, 5],
		[98, 7],
		[100, 9],
		[101, 10],
		[103, 12],
		[104, 13],
		[104, 13],
		[105, 14],
		[105, 14],
		[106, 14],
		[106, 14],
		[107, 14],
		[107, 14],
		[108, 13],
		[110, 12],
		[111, 10],
		[114, 7],
		[116, 4],
		[119, -1],
		[123, -6],
		[127, -12],
		[130, -17],
		[135, -24],
		[139, -30],
		[144, -37],
		[149, -44],
		[154, -50],
		[158, -56],
		[163, -61],
		[167, -66],
		[168, -68],
		[172, -72],
		[174, -75],
		[176, -77],
		[178, -79],
		[178, -80],
		[179, -80],
		[180, -81],
		[180, -81],
		[180, -81],
		[180, -81],
		[181, -81],
		[181, -81],
		[181, -81],
		[182, -79],
		[185, -78],
		[187, -75],
		[190, -72],
		[194, -69],
		[198, -64],
		[204, -58],
		[209, -52],
		[215, -46],
		[220, -39],
		[226, -33],
		[231, -28],
		[236, -22],
		[240, -17],
		[241, -16],
		[244, -12],
		[246, -9],
		[249, -6],
		[250, -5],
		[251, -4],
		[252, -3],
		[253, -2],
		[253, -2],
		[253, -1],
		[254, -1],
		[254, -1],
		[254, -1],
		[254, -1],
		[254, -2],
		[255, -2],
		[256, -4],
		[258, -5],
		[260, -8],
		[263, -11],
		[266, -14],
		[270, -19],
		[276, -24],
		[281, -31],
		[287, -37],
		[292, -43],
		[297, -48],
		[303, -55],
		[307, -61],
		[312, -66],
		[316, -70],
		[318, -72],
		[321, -75],
		[324, -78],
		[326, -81],
		[328, -83],
		[329, -84],
		[330, -85],
		[331, -86],
		[331, -86],
		[331, -87],
		[332, -87],
		[332, -87],
		[332, -88],
		[333, -88],
		[333, -89],
		[334, -89],
		[334, -90],
		[335, -91],
		[336, -92],
		[336, -92],
		[336, -92],
		[336, -93],
		[337, -93],
		[337, -93],
		[337, -93],
	],
	[
		[6, -11],
		[9, -16],
		[12, -20],
		[17, -28],
		[22, -37],
		[27, -46],
		[34, -56],
		[36, -60],
		[41, -67],
		[46, -74],
		[49, -80],
		[53, -86],
		[54, -87],
		[56, -90],
		[58, -92],
		[60, -95],
		[61, -96],
		[62, -96],
		[63, -97],
		[63, -97],
		[64, -97],
		[64, -97],
		[65, -97],
		[65, -97],
		[66, -95],
		[68, -91],
		[71, -82],
		[75, -75],
		[80, -63],
		[85, -48],
		[91, -34],
		[94, -27],
		[99, -14],
		[104, -2],
		[109, 9],
		[111, 12],
		[113, 19],
		[115, 23],
		[116, 25],
		[117, 27],
		[118, 27],
		[118, 27],
		[118, 27],
		[119, 27],
		[120, 27],
		[121, 24],
		[122, 20],
		[123, 17],
		[125, 11],
		[129, -2],
		[132, -12],
		[136, -23],
		[141, -35],
		[145, -49],
		[147, -55],
		[151, -68],
		[154, -77],
		[158, -88],
		[163, -97],
		[166, -105],
		[168, -108],
		[171, -113],
		[174, -116],
		[176, -119],
		[178, -121],
		[180, -122],
		[182, -123],
		[184, -123],
		[185, -123],
		[187, -123],
		[188, -123],
		[191, -123],
		[193, -121],
		[196, -118],
		[198, -115],
		[202, -110],
		[206, -104],
		[210, -98],
		[220, -84],
		[225, -75],
		[232, -62],
		[235, -57],
		[241, -46],
		[246, -36],
		[251, -27],
		[253, -23],
		[255, -18],
		[257, -13],
		[259, -11],
		[260, -7],
		[261, -7],
		[261, -7],
		[261, -6],
		[262, -6],
		[263, -6],
		[264, -7],
		[265, -8],
		[267, -11],
		[269, -14],
		[272, -19],
		[276, -26],
		[280, -34],
		[283, -41],
		[287, -50],
		[291, -58],
		[295, -67],
		[300, -77],
		[304, -86],
		[310, -95],
		[314, -102],
		[319, -110],
		[321, -113],
		[325, -118],
		[327, -122],
		[330, -125],
		[331, -127],
		[332, -128],
	],
	[
		[3, -11],
		[5, -16],
		[8, -22],
		[12, -30],
		[16, -38],
		[20, -46],
		[25, -55],
		[30, -64],
		[34, -71],
		[38, -79],
		[42, -86],
		[43, -88],
		[46, -93],
		[47, -95],
		[49, -98],
		[50, -99],
		[51, -100],
		[51, -101],
		[51, -101],
		[52, -101],
		[52, -101],
		[52, -101],
		[52, -100],
		[54, -97],
		[55, -94],
		[58, -89],
		[61, -83],
		[64, -77],
		[67, -69],
		[72, -60],
		[76, -51],
		[80, -42],
		[84, -33],
		[87, -26],
		[88, -24],
		[90, -20],
		[91, -18],
		[92, -16],
		[92, -15],
		[93, -14],
		[93, -14],
		[93, -14],
		[94, -13],
		[94, -13],
		[94, -14],
		[94, -16],
		[95, -18],
		[96, -23],
		[98, -28],
		[102, -40],
		[106, -51],
		[110, -64],
		[115, -80],
		[117, -87],
		[121, -99],
		[126, -111],
		[130, -120],
		[131, -123],
		[134, -129],
		[136, -133],
		[137, -135],
		[138, -137],
		[139, -138],
		[139, -139],
		[139, -139],
		[139, -139],
		[140, -139],
		[140, -138],
		[141, -136],
		[142, -132],
		[145, -125],
		[148, -116],
		[152, -107],
		[154, -101],
		[157, -92],
		[162, -81],
		[166, -73],
		[169, -64],
		[172, -56],
		[175, -50],
		[178, -44],
		[180, -39],
		[182, -35],
		[183, -33],
		[184, -29],
		[185, -27],
		[186, -26],
		[187, -24],
		[187, -23],
		[187, -23],
		[188, -23],
		[188, -23],
		[188, -22],
		[188, -22],
		[189, -22],
		[189, -24],
		[189, -25],
		[191, -28],
		[192, -31],
		[194, -35],
		[196, -40],
		[198, -44],
		[201, -50],
		[205, -56],
		[208, -62],
		[212, -68],
		[215, -73],
		[218, -78],
		[221, -82],
		[222, -85],
		[225, -88],
		[226, -91],
		[227, -92],
		[228, -93],
		[228, -94],
		[228, -95],
		[229, -95],
		[229, -95],
		[229, -95],
		[229, -96],
		[230, -97],
		[230, -98],
		[231, -98],
		[231, -99],
		[233, -101],
		[233, -102],
		[234, -103],
		[235, -104],
		[236, -105],
		[236, -106],
		[237, -106],
		[237, -106],
		[237, -107],
		[237, -107],
	],
	[
		[0, -2],
		[2, -6],
		[4, -8],
		[8, -13],
		[11, -19],
		[15, -27],
		[20, -34],
		[25, -41],
		[28, -47],
		[29, -49],
		[33, -54],
		[35, -57],
		[37, -60],
		[38, -61],
		[39, -62],
		[40, -63],
		[40, -63],
		[40, -63],
		[41, -63],
		[41, -63],
		[41, -63],
		[42, -63],
		[42, -63],
		[44, -60],
		[46, -55],
		[52, -45],
		[57, -34],
		[60, -27],
		[66, -16],
		[73, -5],
		[79, 5],
		[82, 9],
		[86, 15],
		[89, 20],
		[92, 24],
		[94, 26],
		[96, 28],
		[97, 29],
		[98, 29],
		[98, 29],
		[99, 29],
		[100, 29],
		[101, 29],
		[103, 26],
		[105, 23],
		[108, 17],
		[111, 12],
		[114, 5],
		[118, -2],
		[121, -9],
		[125, -17],
		[126, -20],
		[131, -29],
		[132, -31],
		[135, -36],
		[137, -39],
		[139, -43],
		[141, -46],
		[142, -48],
		[143, -49],
		[144, -50],
		[144, -51],
		[145, -51],
		[145, -51],
		[146, -51],
		[146, -51],
		[146, -51],
		[147, -51],
		[147, -50],
		[148, -48],
		[150, -44],
		[153, -39],
		[156, -33],
		[161, -24],
		[165, -14],
		[170, -7],
		[174, 3],
		[176, 6],
		[180, 12],
		[184, 18],
		[187, 22],
		[190, 26],
		[192, 28],
		[194, 31],
		[196, 32],
		[198, 34],
		[199, 35],
		[200, 35],
		[202, 36],
		[205, 36],
		[207, 36],
		[210, 35],
		[214, 33],
		[218, 30],
		[223, 25],
		[228, 20],
		[233, 13],
		[239, 3],
		[243, -6],
		[249, -15],
		[255, -26],
		[260, -37],
		[265, -46],
		[271, -56],
		[275, -64],
		[277, -67],
		[281, -73],
		[284, -77],
		[286, -80],
		[288, -83],
		[289, -85],
		[289, -86],
	],
	[
		[0, -6],
		[1, -13],
		[2, -16],
		[4, -24],
		[7, -31],
		[10, -37],
		[13, -43],
		[17, -49],
		[17, -50],
		[19, -53],
		[20, -55],
		[22, -56],
		[23, -56],
		[24, -56],
		[24, -56],
		[25, -56],
		[25, -56],
		[26, -56],
		[27, -53],
		[30, -42],
		[35, -30],
		[41, -14],
		[48, 4],
		[51, 12],
		[58, 27],
		[65, 41],
		[67, 45],
		[72, 55],
		[76, 61],
		[79, 64],
		[81, 67],
		[82, 68],
		[83, 69],
		[84, 69],
		[85, 69],
		[87, 69],
		[89, 67],
		[92, 63],
		[95, 57],
		[99, 49],
		[104, 39],
		[110, 30],
		[114, 19],
		[119, 10],
		[124, 1],
		[129, -7],
		[133, -15],
		[135, -18],
		[138, -24],
		[141, -28],
		[144, -31],
		[146, -33],
		[147, -36],
		[148, -37],
		[149, -37],
		[150, -37],
		[150, -37],
		[151, -37],
		[151, -37],
		[152, -37],
		[153, -35],
		[154, -32],
		[156, -27],
		[158, -21],
		[160, -14],
		[163, -7],
		[166, 2],
		[169, 9],
		[172, 17],
		[173, 20],
		[176, 25],
		[178, 30],
		[181, 33],
		[183, 36],
		[184, 38],
		[185, 39],
		[186, 39],
		[187, 40],
		[189, 40],
		[190, 40],
		[192, 38],
		[196, 34],
		[204, 23],
		[211, 13],
		[222, -2],
		[232, -17],
		[244, -36],
		[257, -58],
		[269, -79],
		[281, -102],
		[287, -113],
		[305, -147],
	],
];
export default unistrokes;
