const unistrokes = [
	[
		[137, 139],
		[135, 141],
		[133, 144],
		[132, 146],
		[130, 149],
		[128, 151],
		[126, 155],
		[123, 160],
		[120, 166],
		[116, 171],
		[112, 177],
		[107, 183],
		[102, 188],
		[100, 191],
		[95, 195],
		[90, 199],
		[86, 203],
		[82, 206],
		[80, 209],
		[75, 213],
		[73, 213],
		[70, 216],
		[67, 219],
		[64, 221],
		[61, 223],
		[60, 225],
		[62, 226],
		[65, 225],
		[67, 226],
		[74, 226],
		[77, 227],
		[85, 229],
		[91, 230],
		[99, 231],
		[108, 232],
		[116, 233],
		[125, 233],
		[134, 234],
		[145, 233],
		[153, 232],
		[160, 233],
		[170, 234],
		[177, 235],
		[179, 236],
		[186, 237],
		[193, 238],
		[198, 239],
		[200, 237],
		[202, 239],
		[204, 238],
		[206, 234],
		[205, 230],
		[202, 222],
		[197, 216],
		[192, 207],
		[186, 198],
		[179, 189],
		[174, 183],
		[170, 178],
		[164, 171],
		[161, 168],
		[154, 160],
		[148, 155],
		[143, 150],
		[138, 148],
		[136, 148],
	],
	[
		[0, -4],
		[0, -7],
		[1, -13],
		[3, -20],
		[7, -29],
		[11, -41],
		[18, -57],
		[23, -72],
		[30, -87],
		[36, -104],
		[43, -120],
		[45, -124],
		[50, -136],
		[55, -145],
		[59, -152],
		[63, -158],
		[67, -163],
		[70, -166],
		[70, -167],
		[73, -169],
		[75, -170],
		[77, -171],
		[78, -172],
		[80, -172],
		[81, -172],
		[83, -172],
		[85, -172],
		[88, -172],
		[92, -172],
		[96, -169],
		[100, -165],
		[106, -161],
		[113, -155],
		[125, -144],
		[132, -134],
		[140, -123],
		[147, -113],
		[151, -107],
		[158, -96],
		[165, -85],
		[171, -75],
		[178, -65],
		[183, -55],
		[189, -45],
		[193, -36],
		[197, -28],
		[202, -20],
		[205, -14],
		[206, -12],
		[208, -6],
		[210, -3],
		[212, 0],
		[213, 3],
		[214, 4],
		[214, 5],
		[214, 7],
		[215, 7],
		[215, 8],
		[215, 8],
		[215, 9],
		[213, 9],
		[210, 10],
		[207, 11],
		[203, 12],
		[197, 14],
		[191, 16],
		[182, 18],
		[168, 21],
		[158, 22],
		[144, 23],
		[128, 24],
		[113, 24],
		[96, 24],
		[81, 24],
		[72, 24],
		[58, 23],
		[44, 21],
		[32, 20],
		[22, 19],
		[13, 18],
		[3, 16],
		[-5, 15],
		[-12, 14],
		[-17, 12],
		[-19, 12],
		[-24, 11],
		[-26, 10],
		[-29, 10],
		[-30, 10],
		[-32, 10],
	],
	[
		[2, -3],
		[3, -5],
		[9, -12],
		[11, -18],
		[16, -26],
		[21, -35],
		[27, -45],
		[32, -56],
		[39, -70],
		[46, -84],
		[55, -99],
		[63, -113],
		[72, -125],
		[75, -129],
		[82, -139],
		[89, -148],
		[94, -155],
		[100, -161],
		[104, -166],
		[106, -167],
		[109, -170],
		[112, -171],
		[114, -173],
		[116, -173],
		[118, -173],
		[119, -173],
		[122, -173],
		[124, -173],
		[127, -171],
		[130, -168],
		[135, -164],
		[143, -156],
		[149, -150],
		[158, -140],
		[167, -128],
		[175, -118],
		[178, -111],
		[186, -100],
		[192, -89],
		[198, -81],
		[203, -73],
		[206, -67],
		[209, -61],
		[212, -55],
		[214, -50],
		[216, -45],
		[218, -40],
		[219, -33],
		[220, -28],
		[221, -23],
		[222, -17],
		[222, -13],
		[223, -11],
		[223, -4],
		[223, -1],
		[223, 2],
		[223, 7],
		[223, 10],
		[222, 14],
		[219, 17],
		[215, 20],
		[210, 24],
		[205, 28],
		[200, 31],
		[193, 34],
		[188, 36],
		[180, 39],
		[173, 41],
		[166, 42],
		[158, 44],
		[150, 44],
		[140, 45],
		[125, 45],
		[115, 45],
		[103, 45],
		[97, 45],
		[85, 43],
		[75, 41],
		[66, 39],
		[56, 37],
		[49, 35],
		[41, 33],
		[39, 33],
		[29, 30],
		[28, 30],
		[24, 28],
		[22, 27],
		[20, 25],
		[19, 25],
		[18, 24],
		[17, 23],
	],
	[
		[-1, -1],
		[-1, -5],
		[-1, -11],
		[3, -20],
		[7, -29],
		[12, -43],
		[18, -56],
		[25, -72],
		[30, -85],
		[37, -98],
		[42, -110],
		[49, -124],
		[55, -135],
		[62, -148],
		[68, -159],
		[70, -164],
		[76, -174],
		[83, -183],
		[88, -192],
		[94, -200],
		[95, -202],
		[99, -207],
		[102, -210],
		[103, -212],
		[106, -214],
		[106, -214],
		[108, -215],
		[109, -216],
		[110, -216],
		[111, -216],
		[112, -216],
		[113, -216],
		[115, -216],
		[117, -214],
		[120, -212],
		[122, -209],
		[125, -206],
		[128, -202],
		[132, -197],
		[137, -191],
		[142, -184],
		[147, -177],
		[152, -169],
		[158, -161],
		[162, -152],
		[168, -144],
		[172, -135],
		[177, -126],
		[182, -118],
		[187, -109],
		[191, -101],
		[196, -93],
		[201, -85],
		[205, -78],
		[209, -72],
		[213, -66],
		[217, -61],
		[220, -57],
		[222, -55],
		[224, -51],
		[226, -49],
		[228, -46],
		[229, -44],
		[230, -43],
		[231, -41],
		[232, -40],
		[232, -39],
		[233, -37],
		[233, -36],
		[233, -34],
		[234, -33],
		[234, -31],
		[234, -30],
		[234, -29],
		[234, -28],
		[233, -26],
		[231, -25],
		[229, -23],
		[226, -21],
		[223, -19],
		[219, -18],
		[214, -15],
		[208, -14],
		[197, -10],
		[187, -9],
		[178, -8],
		[166, -7],
		[153, -6],
		[139, -6],
		[125, -6],
		[119, -6],
		[107, -6],
		[94, -6],
		[83, -6],
		[74, -6],
		[64, -6],
		[56, -6],
		[47, -6],
		[39, -6],
		[31, -7],
		[24, -8],
		[21, -8],
		[11, -9],
		[10, -9],
		[6, -10],
		[4, -10],
		[1, -10],
		[0, -10],
		[-1, -10],
		[-2, -10],
		[-3, -10],
		[-4, -11],
		[-4, -11],
		[-6, -11],
		[-6, -12],
		[-7, -13],
		[-7, -13],
	],
	[
		[0, -3],
		[0, -6],
		[0, -9],
		[0, -13],
		[0, -18],
		[-2, -23],
		[-2, -30],
		[-5, -41],
		[-6, -48],
		[-9, -57],
		[-11, -65],
		[-14, -76],
		[-18, -88],
		[-22, -101],
		[-28, -115],
		[-32, -129],
		[-35, -136],
		[-44, -158],
		[-49, -172],
		[-51, -177],
		[-55, -188],
		[-59, -198],
		[-62, -207],
		[-65, -215],
		[-69, -221],
		[-72, -228],
		[-76, -235],
		[-78, -239],
		[-82, -244],
		[-85, -249],
		[-87, -253],
		[-90, -258],
		[-92, -260],
		[-94, -264],
		[-96, -267],
		[-98, -271],
		[-100, -273],
		[-101, -276],
		[-103, -278],
		[-104, -280],
		[-105, -281],
		[-106, -281],
		[-106, -282],
		[-106, -283],
		[-107, -283],
		[-107, -283],
		[-107, -283],
		[-108, -283],
		[-108, -283],
		[-109, -283],
		[-110, -283],
		[-112, -281],
		[-115, -279],
		[-118, -275],
		[-125, -267],
		[-129, -263],
		[-138, -252],
		[-142, -248],
		[-149, -238],
		[-162, -223],
		[-171, -213],
		[-179, -203],
		[-182, -198],
		[-196, -181],
		[-200, -175],
		[-208, -165],
		[-214, -157],
		[-221, -147],
		[-227, -139],
		[-238, -123],
		[-245, -112],
		[-250, -102],
		[-257, -92],
		[-259, -85],
		[-265, -75],
		[-270, -65],
		[-275, -55],
		[-280, -45],
		[-284, -34],
		[-288, -25],
		[-292, -17],
		[-295, -9],
		[-296, -7],
		[-298, -2],
		[-299, 2],
		[-301, 5],
		[-301, 8],
		[-302, 10],
		[-302, 11],
		[-302, 12],
		[-302, 13],
		[-302, 14],
		[-302, 14],
		[-302, 15],
		[-302, 15],
		[-301, 15],
		[-299, 15],
		[-297, 15],
		[-294, 15],
		[-289, 15],
		[-283, 15],
		[-272, 14],
		[-263, 12],
		[-252, 11],
		[-239, 9],
		[-224, 6],
		[-210, 4],
		[-194, 2],
		[-178, 0],
		[-164, -3],
		[-148, -5],
		[-133, -6],
		[-119, -8],
		[-105, -9],
		[-98, -9],
		[-86, -10],
		[-66, -12],
		[-59, -12],
		[-39, -12],
		[-32, -12],
		[-22, -12],
		[-13, -12],
		[-4, -12],
		[-1, -12],
		[6, -12],
		[10, -12],
		[13, -12],
		[16, -12],
		[18, -12],
		[19, -12],
		[19, -12],
		[20, -12],
		[20, -12],
		[20, -12],
		[20, -12],
	],
];
export default unistrokes;
