const unistrokes = [
	[
		[7, 0],
		[13, 0],
		[24, 0],
		[38, 0],
		[67, 0],
		[86, 0],
		[96, 0],
		[117, 0],
		[137, 0],
		[156, 0],
		[174, 0],
		[189, 0],
		[195, 0],
		[206, 0],
		[217, 0],
		[220, 0],
		[225, 0],
		[229, 0],
		[232, 0],
		[234, 0],
		[235, 0],
		[235, 0],
		[234, 0],
		[230, 0],
		[223, 0],
		[211, 0],
		[199, 2],
		[182, 5],
		[161, 7],
		[140, 10],
		[117, 13],
		[93, 16],
		[70, 19],
		[47, 21],
		[28, 23],
		[20, 24],
		[4, 25],
		[-7, 25],
		[-10, 26],
		[-18, 27],
		[-22, 27],
		[-25, 27],
		[-26, 27],
		[-27, 27],
		[-28, 27],
		[-27, 27],
		[-24, 26],
		[-16, 25],
		[-8, 23],
		[2, 22],
		[14, 20],
		[28, 19],
		[47, 17],
		[66, 16],
		[87, 16],
		[110, 16],
		[132, 16],
		[153, 16],
		[171, 16],
		[179, 18],
		[193, 20],
		[206, 22],
		[209, 22],
		[217, 24],
		[222, 25],
		[225, 26],
		[228, 26],
		[229, 27],
		[229, 27],
	],
	[
		[9, 0],
		[15, 0],
		[26, 0],
		[39, 0],
		[56, 0],
		[73, 0],
		[92, 0],
		[111, 0],
		[132, 0],
		[151, 0],
		[158, 0],
		[172, 0],
		[185, 0],
		[193, 0],
		[196, 0],
		[201, 0],
		[204, 0],
		[208, 0],
		[209, 0],
		[209, 0],
		[209, 0],
		[208, 0],
		[203, 3],
		[195, 6],
		[184, 10],
		[171, 14],
		[144, 23],
		[131, 26],
		[108, 31],
		[68, 40],
		[54, 43],
		[29, 47],
		[19, 49],
		[-17, 55],
		[-24, 57],
		[-38, 58],
		[-42, 59],
		[-50, 60],
		[-56, 61],
		[-58, 62],
		[-60, 62],
		[-61, 62],
		[-60, 62],
		[-58, 62],
		[-54, 63],
		[-47, 63],
		[-40, 64],
		[-29, 65],
		[-13, 66],
		[4, 67],
		[40, 67],
		[54, 67],
		[79, 67],
		[103, 67],
		[126, 67],
		[149, 67],
		[156, 66],
		[173, 65],
		[186, 64],
		[190, 64],
		[199, 64],
		[205, 64],
		[209, 64],
		[211, 64],
		[213, 64],
		[214, 64],
		[214, 64],
		[214, 64],
		[214, 65],
	],
	[
		[3, 0],
		[10, 0],
		[15, 0],
		[23, 0],
		[34, 0],
		[45, 0],
		[59, 0],
		[75, 0],
		[89, 0],
		[103, 0],
		[108, 0],
		[122, 0],
		[132, 0],
		[143, 0],
		[151, 0],
		[158, 0],
		[160, 0],
		[165, 0],
		[168, 0],
		[171, 0],
		[173, 0],
		[174, 0],
		[174, 0],
		[174, 0],
		[175, 0],
		[174, 0],
		[172, 1],
		[169, 2],
		[161, 4],
		[154, 6],
		[144, 8],
		[134, 11],
		[122, 14],
		[108, 18],
		[95, 21],
		[89, 22],
		[77, 25],
		[65, 27],
		[56, 29],
		[52, 29],
		[46, 30],
		[41, 31],
		[37, 31],
		[34, 32],
		[32, 32],
		[31, 32],
		[31, 32],
		[30, 32],
		[30, 32],
		[30, 32],
		[31, 32],
		[34, 32],
		[37, 31],
		[44, 30],
		[53, 29],
		[64, 27],
		[78, 25],
		[94, 24],
		[111, 23],
		[128, 21],
		[147, 20],
		[162, 20],
		[170, 20],
		[184, 19],
		[196, 19],
		[207, 19],
		[216, 19],
		[220, 19],
		[226, 19],
		[231, 19],
		[235, 19],
		[237, 20],
		[238, 20],
		[239, 20],
		[240, 20],
		[240, 20],
		[241, 21],
		[241, 21],
	],
	[
		[6, 0],
		[12, 0],
		[22, 0],
		[33, 0],
		[49, 0],
		[68, 0],
		[87, 0],
		[106, 0],
		[123, 0],
		[138, 0],
		[145, 0],
		[159, 0],
		[170, 0],
		[178, 0],
		[186, 0],
		[188, 0],
		[193, 0],
		[196, 0],
		[198, 0],
		[200, 0],
		[200, 0],
		[200, 0],
		[201, 0],
		[201, 0],
		[200, 0],
		[198, 1],
		[192, 3],
		[184, 6],
		[174, 9],
		[160, 13],
		[145, 17],
		[128, 21],
		[109, 26],
		[88, 29],
		[69, 33],
		[52, 36],
		[44, 37],
		[29, 40],
		[16, 42],
		[7, 44],
		[3, 44],
		[-2, 45],
		[-7, 46],
		[-9, 46],
		[-10, 46],
		[-11, 46],
		[-12, 46],
		[-10, 46],
		[-8, 46],
		[-2, 46],
		[5, 46],
		[16, 46],
		[30, 46],
		[47, 46],
		[68, 45],
		[91, 43],
		[115, 42],
		[140, 42],
		[163, 42],
		[171, 42],
		[187, 42],
		[201, 42],
		[204, 42],
		[213, 42],
		[218, 42],
		[222, 42],
		[223, 42],
		[225, 42],
		[226, 42],
		[226, 43],
		[226, 43],
		[226, 43],
		[226, 43],
		[226, 44],
		[226, 44],
		[226, 44],
	],
	[
		[5, 0],
		[11, 0],
		[23, 0],
		[45, 0],
		[54, 0],
		[73, 0],
		[92, 0],
		[111, 0],
		[130, 0],
		[151, 0],
		[166, 0],
		[173, 0],
		[184, 0],
		[195, 0],
		[204, 0],
		[206, 0],
		[210, 0],
		[214, 0],
		[215, 0],
		[216, 0],
		[215, 0],
		[209, 0],
		[191, 5],
		[174, 11],
		[151, 17],
		[126, 25],
		[95, 34],
		[66, 41],
		[52, 44],
		[25, 49],
		[15, 50],
		[-2, 53],
		[-8, 54],
		[-19, 54],
		[-28, 55],
		[-32, 56],
		[-36, 56],
		[-37, 56],
		[-38, 56],
		[-37, 56],
		[-31, 54],
		[-22, 52],
		[-10, 50],
		[17, 45],
		[40, 41],
		[54, 39],
		[78, 36],
		[105, 32],
		[130, 29],
		[152, 28],
		[175, 26],
		[196, 25],
		[203, 25],
		[217, 25],
		[230, 25],
		[233, 25],
		[241, 25],
		[246, 25],
		[249, 25],
		[252, 26],
		[252, 27],
		[253, 27],
	],
	[
		[9, 0],
		[16, 0],
		[23, 0],
		[32, 0],
		[53, 0],
		[58, 0],
		[67, 0],
		[82, 0],
		[84, 0],
		[87, 0],
		[92, 0],
		[93, 0],
		[94, 0],
		[92, 0],
		[89, 0],
		[85, 0],
		[77, 0],
		[68, 0],
		[57, 0],
		[43, 1],
		[13, 1],
		[-2, 1],
		[-18, 1],
		[-37, 1],
		[-46, 0],
		[-50, -1],
		[-61, -2],
		[-63, -3],
		[-65, -3],
		[-66, -4],
		[-66, -4],
		[-64, -4],
		[-61, -4],
		[-57, -4],
		[-40, -5],
		[-27, -6],
		[-15, -6],
		[20, -7],
		[39, -7],
		[59, -7],
		[87, -7],
		[103, -7],
		[117, -7],
		[130, -6],
		[137, -5],
		[141, -5],
		[145, -5],
		[146, -4],
		[147, -4],
		[148, -4],
		[148, -4],
		[148, -4],
	],
	[
		[2, 0],
		[4, 0],
		[10, 0],
		[17, 0],
		[25, 0],
		[38, 0],
		[50, 0],
		[66, 0],
		[80, 0],
		[95, 0],
		[109, 0],
		[115, 0],
		[126, 0],
		[135, 0],
		[138, 0],
		[143, 0],
		[147, 0],
		[150, 0],
		[151, 0],
		[152, 0],
		[152, 0],
		[151, 0],
		[147, 0],
		[143, 0],
		[133, 0],
		[124, 0],
		[113, 0],
		[99, 0],
		[84, 0],
		[68, 0],
		[51, 0],
		[34, 0],
		[26, 0],
		[12, 0],
		[1, 0],
		[-3, 0],
		[-11, 0],
		[-16, 0],
		[-22, 0],
		[-24, 0],
		[-25, 0],
		[-25, 0],
		[-25, 0],
		[-23, 0],
		[-20, 0],
		[-14, 0],
		[-6, -1],
		[3, -2],
		[17, -4],
		[48, -6],
		[61, -8],
		[86, -10],
		[112, -11],
		[166, -13],
		[189, -14],
		[197, -14],
		[227, -14],
		[231, -14],
		[240, -14],
		[249, -14],
		[251, -14],
		[253, -14],
	],
	[
		[6, 0],
		[13, 0],
		[50, 0],
		[69, 0],
		[94, 0],
		[120, 0],
		[145, 0],
		[176, 0],
		[187, 0],
		[210, 0],
		[231, 0],
		[237, 0],
		[249, 0],
		[253, 0],
		[260, 0],
		[265, 0],
		[268, 0],
		[269, 0],
		[270, 0],
		[269, 0],
		[263, 0],
		[255, 1],
		[232, 4],
		[216, 6],
		[199, 9],
		[180, 11],
		[161, 13],
		[140, 14],
		[117, 15],
		[95, 16],
		[70, 17],
		[43, 17],
		[19, 17],
		[10, 17],
		[-7, 16],
		[-15, 14],
		[-20, 13],
		[-24, 12],
		[-24, 12],
		[-24, 12],
		[-24, 12],
		[-24, 11],
		[-22, 11],
		[-11, 10],
		[-4, 10],
		[6, 10],
		[18, 10],
		[34, 10],
		[53, 10],
		[72, 10],
		[95, 10],
		[117, 10],
		[140, 10],
		[161, 10],
		[181, 11],
		[189, 12],
		[204, 13],
		[218, 14],
		[228, 14],
		[231, 15],
		[237, 16],
		[242, 17],
		[244, 17],
		[247, 18],
		[248, 18],
	],
];
export default unistrokes;
